<template>
    <div class="home">
        <wd-navbar :title="$LANG.slang">
      <span slot="left" @click="$router.back()">
        <wd-icon name="arrow-left" />
      </span>
<!--            <span slot="right" @click="shuaxin" >Save</span>-->

        </wd-navbar>

        <wd-radio-group v-model="langIndex" cell @change="selectLang">
<!--            <wd-radio :value="index"  v-for="(item, index) in langs" :key="index">{{item}}</wd-radio>-->
            <wd-radio value="0" :selected="langIndex==0?'selected':''" ><span class="fi fi-us" style="font-size: 16px;"></span>&nbsp;&nbsp;{{langs[0]}}</wd-radio>
            <wd-radio value="1" :selected="langIndex==1?'selected':''" ><span class="fi fi-cn" style="font-size: 16px;"></span>&nbsp;&nbsp;{{langs[1]}}</wd-radio>
            <wd-radio value="2" :selected="langIndex==2?'selected':''"><span class="fi fi-hk" style="font-size: 16px;"></span>&nbsp;&nbsp;{{langs[2]}}</wd-radio>
            <wd-radio value="3" :selected="langIndex==3?'selected':''" ><span class="fi fi-kr" style="font-size: 16px;"></span>&nbsp;&nbsp;{{langs[3]}}</wd-radio>
            <wd-radio value="4" :selected="langIndex==4?'selected':''" ><span class="fi fi-jp" style="font-size: 16px;"></span>&nbsp;&nbsp;{{langs[4]}}</wd-radio>
            <wd-radio value="5" :selected="langIndex==5?'selected':''" ><span class="fi fi-pt" style="font-size: 16px;"></span>&nbsp;&nbsp;{{langs[5]}}</wd-radio>
            <wd-radio value="6" :selected="langIndex==6?'selected':''" ><span class="fi fi-es" style="font-size: 16px;"></span>&nbsp;&nbsp;{{langs[6]}}</wd-radio>
            <wd-radio value="7" :selected="langIndex==7?'selected':''" ><span class="fi fi-fr" style="font-size: 16px;"></span>&nbsp;&nbsp;{{langs[7]}}</wd-radio>
        </wd-radio-group>

    </div>
</template>
<script>
    import { langs } from '@/api/lang'
    import { lindex } from '@/api/lang'
    export default {
        name: "Index",
        data(){
            return{
                langIndex:0,
                langs: ["English","简体中文","繁體中文","한국어.","日本語","Português","Español","Français"]
            }
        },

        methods: {

            shuaxin(){
                // this.$router.go(0)
                window.location.reload();
            },

            // 选择语言
            selectLang(val) {
                this.langIndex = val
                localStorage.setItem("lang",val)
                localStorage.setItem("reload","yes")
                window.location.reload();
                // window.close()
              // this.$router.go(-1)
            },

        },
        created() {
            this.langIndex=lindex()

        },
        mounted() {
            if(localStorage.getItem("reload") == "yes"){
              localStorage.setItem("reload","no")
              this.$router.go(-1)
            }
        },
        beforeDestroy() {

        }
    }
</script>
