import axios from 'axios'

// 创建axios实例
const service = axios.create({
  // baseURL: 'http://127.0.0.1:9100/api/system', // api 的 base_url
  // baseURL: 'https://api.fxcoinbtc.com/api/system', // api 的 base_url
  baseURL: 'https://api.defi8.vip/api/v1/', // api 的 base_url
  // baseURL: 'http://118.107.45.54/api/v1/', // api 的 base_url
  timeout: 3000 // 请求超时时间
})
// // request 拦截器
// service.interceptors.request.use(
//   config => {
//     return config
//   },
//   error => {
//     console.log(error) // for debug
//     Promise.reject(error)
//   }
// )
/* 请求拦截器 */
axios.interceptors.request.use((config) => {
  // config.data = qs.stringify(config.data) // 转为formdata数据格式
  return config;
}, (error) => {
  return Promise.reject(error);
});

// response 拦截器
service.interceptors.response.use(
  response => {
    return response.data
  },
  error => {
    console.log('err' + error) // for debug
    return Promise.reject(error)
  }
)
export default service
