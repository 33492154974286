<template>
  <div style="width: 100%;" class="row-bg">

    <wd-navbar :title="this.$LANG.usdtin" >
      <span slot="left" @click="goback">
        <wd-icon name="arrow-left" />
      </span>
    </wd-navbar>

    <el-row style="width: 100%;padding: 20px">
      <el-form  ref="ruleForm" class="demo-ruleForm" >
        <div class="form-label">{{this.$LANG.rechargenumber}}</div>
        <el-form-item >
          <wd-input v-model="usdtin.number" :placeholder="this.$LANG.plzhuanrunum" style="width: 100%;"></wd-input>
        </el-form-item>
        <div class="form-label">{{this.$LANG.hkaddress}}</div>
        <el-form-item >
          <wd-input v-model="usdtin.address" :placeholder="$LANG.hkaddress" style="width: 100%;"></wd-input>
        </el-form-item>
        <div class="form-label">{{this.$LANG.cointype}}</div>
        <el-form-item>
            <wd-radio-group v-model="netType" shape="button" @change="typeChange" style="text-align: left;">
              <wd-radio value="OMNI" v-if="coinType=='USDT'">OMNI</wd-radio>
              <wd-radio value="ERC20" v-if="coinType=='USDT'">ERC20</wd-radio>
              <wd-radio value="TRC20" v-if="coinType=='USDT'">TRC20</wd-radio>
              <wd-radio value="BTC" v-if="coinType=='BTC'">BTC</wd-radio>
              <wd-radio value="ETH" v-if="coinType=='ETH'">ETH</wd-radio>
            </wd-radio-group>
        </el-form-item>
        <div class="form-label">{{this.$LANG.address}}</div>
        <el-form-item >
          <div style="background: #F5F5F5;border-radius: 5px;">
            <div style="text-align: left;font-weight: 600;padding: 0 10px;">{{url}}</div>
            <div @click="copyUrl" style="text-align: right;padding-right: 15px;color: #4d80f0;font-weight: bold;line-height: 30px"  :data-clipboard-text="url" class="copy-btn">{{$LANG.clickcopy}}</div>
          </div>
        </el-form-item>
        <div class="form-label">{{$LANG.jietupz}}</div>
        <el-form-item style="text-align: left">
          <wd-upload
                  v-model="fileList"
                  :limit="1"
                  :action=this.$IMG_UPLOAD_URL
                  @exceed="handleExceed"
                  @success="handleSuccess"
                  @fail="handleError"
                  @remove="handleRemove"
                  style="width: 100%;float: left"
          ></wd-upload>
        </el-form-item>
        <wd-button type="primary" @click="submitUsdtinForm()" style="width: 100%;border-radius: 5px">{{this.$LANG.submit}}</wd-button>
      </el-form>

    </el-row>

  </div>
</template>


<script>
  import { Api } from '@/api/interface' // 引入接口
  import { TokenApi } from '@/api/interface' // 引入接口
  import QRCode from 'qrcodejs2'
  import Clipboard from 'clipboard';

  export default {
    name: "Exchange",
    data() {
      return {
        // show_kefu:true,
        bgdiv: {
          backgroundImage:'url(' + require('@/assets/quickBuyBg.png') + ')',
          backgroundRepeat:'no-repeat',
        },
        dialogZXVisible:false,
        fileList:[],
        usdtin:{txid:null,number:null,currency:null,type:null,address:null},
        coinType:'USDT',
        netType:'ERC20',
        url:'',
        activeName: 'first',
        tableData: [],
        walletData:[]
      }
    },
    methods:{
      // gotoKefu(){
      //   this.$router.push({ path: '/kefu' });
      // },
      handleExceed ({ files, fileList }) {
        this.$toast.warning('error')
      },
      handleSuccess ({ response, file, fileList }) {
        // this.imgs.push(response.data)
        // console.log("response.data:" + this.fileList[0].response.data)
        // console.log(this.fileList)
        this.$toast.success(`image [${file.name}] upload success`)
        this.usdtin.txid = this.fileList[0].response.data;
      },
      handleRemove ({ file, fileList }) {
        console.log(this.fileList)
      },
      handleError ({ error, file, fileList }) {
        this.$toast.error('upload error')
      },
      goback(){
        this.$router.go(-1)
      },

      UsdtIn(){
        TokenApi('/token/addIn',this.usdtin,this,"post").then(data => {
          if(data.code==0){
            this.$message({
              message: this.$LANG.success,
              type: 'success'
            });
          }else{
            this.$message({
              message: data.msg,
              type: 'success'
            });
          }
          console.log(data.data)
        })
      },
      // 复制链接
      copyUrl() {
        let _this = this;
        let clipboard = new Clipboard(".copy-btn"); // 这里括号里填写上面点击事件绑定的class名
        clipboard.on("success", e => {
          // 复制成功，提示根据自己项目实际使用的UI来写
          _this.$message.success("Copy Success")
          // 释放内存
          clipboard.destroy();
        });
        clipboard.on("error", e => {
          // 不支持复制，提示根据自己项目实际使用的UI来写
          _this.$message.error("browser dose not support this action")
          // 释放内存
          clipboard.destroy();
        });
      },
      typeChange (val){
        console.log(this.coinCode + this.netType)
        for(var i=0;i<this.walletData.length;i++){
          if(this.walletData[i].type == this.netType){
            this.url = this.walletData[i].address;
            this.usdtin.type = this.walletData[i].type;
          }
        }
      },
      getAddress(){
        let postData = {} // 请求参数
        Api('/no_token/config/2',postData).then(data => {
          if(data.code==0){
            if(data.data.USDT_ERC20 && this.coinType == "USDT"){
                this.walletData.push({address:data.data.USDT_ERC20,type:"ERC20",coin:"USDT"});
            }
            if(data.data.USDT_TRC20 && this.coinType == "USDT"){
                this.walletData.push({address:data.data.USDT_TRC20,type:"TRC20",coin:"USDT"});
            }
            if(data.data.USDT_OMNI && this.coinType == "USDT"){
                this.walletData.push({address:data.data.USDT_OMNI,type:"OMNI",coin:"USDT"});
            }
            if(data.data.BTC && this.coinType == "BTC"){
                this.walletData.push({address:data.data.BTC,type:"BTC",coin:"BTC"});
            }
            if(data.data.ETH && this.coinType == "ETH"){
                this.walletData.push({address:data.data.ETH,type:"ETH",coin:"ETH"});
            }

            // console.log(this.walletData)
            this.url = this.walletData[0].address
            this.netType = this.walletData[0].type;
            // this.coinCode = this.walletData[0].coin;
            // this.usdtin.address = this.walletData[0].address;
            this.usdtin.type = this.walletData[0].type;
            // this.usdtin.currency = this.walletData[0].coin;
            // document.getElementById(this.coinCode+this.netType).style.display = "block";
          }
          console.log(data.data)
        })
      },
      submitUsdtinForm(){
        if(this.usdtin.address && this.usdtin.number){
          this.UsdtIn();
        }else{
          this.$message.error("Number and address can not be empty")
        }
      },
    },
    created() {
      this.getAddress();
      //获取传入参数
      this.coinType = this.$route.query.coinType;
      this.usdtin.currency = this.coinType;
    }

  }
</script>
