<template>
  <div style="" >
    <wd-navbar :title="this.$LANG.kefu" style="background: rgba(30, 32, 38, 0.8);position: fixed;top: 0px;">
      <span slot="left" @click="goback">
        <wd-icon name="arrow-left" />
      </span>
    </wd-navbar>
    <div style="width: 100%;margin-bottom: 50px;margin-top: 44px;overflow-y: scroll;overflow:scroll;" id="chatdiv">

      <div v-for="(item, index) in msgList" :key="index">
        <div style="padding: 5px;text-align: left" v-if="item.uid == 'system'">
          <p class="msgsender item-color-buy">System</p>
          <div v-if="item.type==2" style="padding: 10px">
            <img :src="item.msg" style="max-width: 100%;max-height: 600px;border-radius: 5px">
          </div>
          <p v-else class="msgbody" style="background: #00CF8C">{{item.msg}}<br><span style="float: right;font-size: 9px;;margin-top: 5px">{{item.time}}</span></p>
        </div>
        <div style="padding: 5px;text-align: left" v-else>
          <p class="msgsender item-color-sell">You</p>
          <div v-if="item.type==2" style="padding: 10px">
            <img :src="item.msg" style="max-width: 100%;max-height: 600px;border-radius: 5px">
          </div>
          <p v-else class="msgbody" style="background: #F5465D">{{item.msg}}<br><span style="float: right;font-size: 9px;margin-top: 5px">{{item.time}}</span></p>
        </div>
      </div>
    </div>
    <div style="width: 100%;height: 50px;line-height: 50px;position: fixed;bottom: 0px;left: 0px;border-top: 1px #d9d9d9 solid;background: #fff">
      <div style="width: 70%;float: left;">
        <wd-input style="width: 100%;padding-left:10px;margin: 6px;
    border: 1px #333 solid;" v-model="msg"></wd-input>
      </div>
      <div style="width: 30%;float: left;text-align: right">
        <wd-button style="width: 60px;margin-right: 15px;height: 30px" type="success" size="mini" @click="sendMsg" >{{this.$LANG.sendmessage}}</wd-button>
      </div>
    </div>

    <div style="width: 100%;height: 50px;line-height: 50px;position: fixed;bottom: 0px;left: 0px;border-top: 1px #d9d9d9 solid;background: #fff">
      <div style="position: relative;width: 100%">
        <div style="padding-top: 7px;padding-left: 7px;padding-right: 130px;">
          <wd-input style="width: 100%;padding-left:10px;border: 1px #333 solid;" v-model="msg"></wd-input>
        </div>
        <div style="width: 120px;position: absolute;text-align: right;right: -5px;top:-5px;padding-top: 3px">
          <div style="width: 40px;float: left;margin-top: 7px" @click="dialogVisible=true">
            <el-icon class="el-icon-upload" style="font-size: 30px" />
          </div>
          <wd-button style="width: 60px;margin-right: 10px;height: 30px;font-size: 11px" type="success" size="mini" @click="sendMsg">{{this.$LANG.sendmessage}}</wd-button>
        </div>
      </div>
    </div>

    <wd-popup v-model="dialogVisible" position="bottom" style="padding: 20px;" closable >

      <p style="margin-top:20px;font-size: 12px;font-family: Source Han Sans CN;font-weight: 400;line-height: 17px;color: #9AA4AF;text-align: left;width: 100%;float: left">
        Tips：only png,jpg,jpeg，limit 1M。</p>

      <wd-upload
          v-model="fileList"
          :limit="1"
          :action=this.$IMG_UPLOAD_URL
          @exceed="handleExceed"
          @success="handleSuccess"
          @fail="handleError"
          @remove="handleRemove"
          style="width: 100%;float: left"
      ></wd-upload>

      <el-button type="primary" @click="sendImg" style="width: 100%;background: linear-gradient(130deg, #1188E3 0%, #1E56A0 100%);border-radius: 8px;border:none;margin-top: 30px;
    margin-bottom: 20px;">{{this.$LANG.sendmessage}}</el-button>


    </wd-popup>
  </div>
</template>

<script>
import { TokenApi } from '@/api/interface' // 引入接口

export default {
  data() {
    return {
      msgList: [],
      timer:null,
      dialogVisible:false,
      msg:'',
      msg_id:0,
      img:'',
      token:'',
      userinfo:{id:"youke",username:"youke"},
      fileList:[],
    }
  },
  methods: {
    handleExceed ({ files, fileList }) {
      this.$toast.warning('only one image limit every time')
    },
    handleSuccess ({ response, file, fileList }) {
      this.img = response.data
      console.log("response.data:" + this.fileList[0].response.data)
      console.log(this.fileList)
    },
    handleRemove ({ file, fileList }) {
      console.log(this.fileList)
    },
    handleError ({ error, file, fileList }) {
      this.$toast.error('image send error')
    },
    handleChange(value) {
      console.log(value);
    },
    goback() {
      this.$router.go(-1)
    },
    // 滚动到最底部
    toBottom(){
      this.$nextTick(()=>{
        var scrollDom = document.getElementById('chatdiv');
        scrollDom.scrollTop = scrollDom.scrollHeight
        console.log("hhhhh:::"+scrollDom.scrollHeight)
      })
    },
    // scrollToBottom() {
    //     this.$nextTick(() => {
    //         var container = this.$el.querySelector("#chatdiv");
    //         container.focus();
    //         container.scrollTop = container.scrollHeight;
    //     })},

    // 滚动到底部
    scrollToBottom () {
      this.$nextTick(() => {
        setTimeout(() => {
          var scrollTop = this.$el.querySelector('#chatdiv')
          scrollTop.scrollTop = scrollTop.scrollHeight
        }, 13)
      })
    },

    getMsg(){
      let postData = {}// 请求参数
      TokenApi('UserMsg',postData,this).then(data => {
        if(data.status==0){
          var res = data.data.data
          if(res){
            let Base64 = require('js-base64').Base64
            for(var i=0;i<res.length;i++){
              res[i].msg = Base64.decode(res[i].msg)
              if(res[i].type == 2){
                res[i].msg = this.$IMG_URL + res[i].msg
              }
            }
            this.msgList = res;
          }
          this.scrollToBottom()
        }
        // console.log(data.data)
      })
    },
    sendMsg(){
      if(!this.msg || this.msg == ""){
        this.$message({
          message: 'Input empty',
          type: 'error'
        });
        return;
      }
      this.msg_id = this.msg_id + 1;
      let postData = {
        id:this.msg_id,
        type:1,
        uid:this.userinfo.id,
        fromUserName:this.userinfo.username,
        toUserName:"system",
        toid:'system',
        msg:this.msg
      }// 请求参数
      // TokenApi('NewUserMsg',postData,this).then(data => {
      //     if(data.status==0){
      //         this.msg = ''
      //         // this.msgList.push({sender_id:2,msg:postData.msg,type:postData.type})
      //         this.getMsg();
      //     }
      //     // console.log(data.data)
      // })
      this.$socket.emit("sendMsg",JSON.stringify(postData));
      this.msg = '';
    },
    sendImg(){
      this.dialogVisible=false;
      if(!this.img || this.img == ""){
        this.$message({
          message: 'Input empty',
          type: 'error'
        });
        return;
      }
      this.msg_id = this.msg_id + 1;
      let postData = {
        id:this.msg_id,
        type:2,
        uid:this.userinfo.id,
        fromUserName:this.userinfo.username,
        toUserName:"system",
        toid:'system',
        msg:this.img
      }
      // // 请求参数
      // TokenApi('NewUserMsg',postData,this).then(data => {
      //     if(data.status==0){
      //         this.img = ''
      //         this.getMsg()
      //     }
      // })
      this.$socket.emit("sendMsg",JSON.stringify(postData));
      this.img = '';
    },
    getInfo(){
      let postData = {}// 请求参数
      TokenApi('/UserInfo',postData,this,'get').then(data => {
        if(data.status==0){
          this.userinfo = data.data;
          this.$socket.emit("onLine",this.userinfo.id);
        }
        console.log(data.data)
      })
    },
    //获取用户本地ip的方法



  },
  created() {
    // this.getMsg();
    let that = this;
    document.onkeypress = function(e) {
      var keycode = document.all ? event.keyCode : e.which;
      if (keycode == 13) {
        that.sendMsg();
        return false;
      }
    };
    this.token = localStorage.getItem("accessToken")
    if(this.token){
      this.getInfo();
    }else{
      //36代表36进制
      if(!localStorage.getItem("randomToken")){
        const str = Math.random().toString(36).substr(2,10)
        localStorage.setItem("randomToken",str);
        this.userinfo.id = str;
        this.userinfo.username = str;
      }else{
        this.userinfo.id = localStorage.getItem("randomToken")
        this.userinfo.username = localStorage.getItem("randomToken")
      }
      console.log(this.userinfo.id)
      this.$socket.emit("onLine",this.userinfo.id);
    }


  },
  mounted() {

  },
  beforeDestroy() {
    // clearInterval(this.timer);
  },
  updated(){
    // 聊天定位到底部
    // let ele = document.getElementById('chatdiv');
    // ele.scrollTop = ele.scrollHeight;
    // this.scrollToBottom()
  },
  sockets: {
    //查看socket是否渲染成功
    connect() {
      console.log("链接成功");
    },
    online(res){
      this.$socket.emit("historyMsg",this.userinfo.id);
    },
    disconnect(){
      console.log("断开链接");
    },//检测socket断开链接
    reconnect(){
      console.log("重新链接");
      this.$socket.emit("onLine",this.userinfo.id);
    },
    //客户端接收后台传输的socket事件
    message(data) {
      let res = JSON.parse(data);
      // this.$socket.emit('readMsg',JSON.stringify({id:res.id,uid:this.userinfo.id}));
      console.log("getMsg:"+res);
      if(res.type == 2){
        res.msg = this.$IMG_URL + res.msg
      }
      this.msgList.push(res);
    },
    historyMsg(data) {
      let res = JSON.parse(data);
      if(res.type == 2){
        res.msg = this.$IMG_URL + res.msg
      }
      this.msgList.push(res);
      console.log("historyMsg:"+res);
    },
    receive(data) {
      let res = JSON.parse(data);
      this.msg_id = res.id;
      if(res.type == 2){
        res.msg = this.$IMG_URL + res.msg
      }
      console.log("getMsg:"+res);
      this.msgList.push(res);
    },
  }
}
</script>

<style scoped>
.msgsender{
  font-size: 14px;margin: 0px
}
.msgbody{
  border-radius: 5px;padding: 10px;color: #fafafa;width: fit-content;margin-top: 5px;padding-bottom: 20px;
}
</style>
