<template>
  <div class="mine" style="padding-top: 100px">

    <el-row style="border: 1px #d9d9d9 solid;width: 1003px;height: 653px;margin: auto;" justify="center">

      <div style="width: 300px;float: left;height: 100%;overflow-y: scroll;">
        <el-tabs style="padding:0px 5px;" class="bottombox" @tab-click="tabClick" v-model="activeTab">
          <el-tab-pane label="最新消息" name="first">

            <div v-for="(item, index) in userList" :key="index">
              <div  class="my-cell" @click="gotochat(item)" style="color: #1c1e24">
                {{item.user_name}}
                <wd-icon name="arrow-right" class="icon"></wd-icon>
                <wd-badge :value="item.count" style="float: right;margin-top: 5px;margin-right: 10px" v-if="item.count"></wd-badge>
                <span style="float: right;margin-right: 20px;font-size: 9px;color: #999999">{{item.ip}}</span>
              </div>
              <div class="line"></div>
            </div>
            <el-empty description="empty data" style="width: 30%;margin: auto;color: #666" v-if="userList.length<1"></el-empty>

          </el-tab-pane>
          <el-tab-pane  label="历史接待" name="second">

            <div v-for="(item, index) in hisList" :key="index">
              <div  class="my-cell" @click="gotochat(item)" style="color: #1c1e24">
                {{item.user_name}}
                <wd-icon name="arrow-right" class="icon"></wd-icon>
                <wd-badge :value="item.count" style="float: right;margin-top: 5px;margin-right: 10px" v-if="item.count"></wd-badge>
                <span style="float: right;margin-right: 20px;font-size: 9px;color: #999999" v-if="item.ip">{{item.ip}}</span>
              </div>
              <div class="line"></div>
            </div>
            <el-empty description="empty data" style="width: 30%;margin: auto;color: #666" v-if="hisList.length<1"></el-empty>

          </el-tab-pane>

        </el-tabs>
      </div>
      <div style="float: left;width: 700px;height: 100%;border-left: 1px #d9d9d9 solid">

          <div style="width: 100%;height: 600px;overflow-y: scroll;" id="chatdiv">
            <div v-for="(item, index) in msgList" :key="index">
              <div style="padding: 5px;text-align: left" v-if="item.uid == 'system'">
                <p class="msgsender item-color-buy">System</p>
                <div v-if="item.type==2" style="padding: 10px">
                  <img :src="item.msg" style="max-width: 100%;max-height: 600px;border-radius: 5px">
                </div>
                <p v-else class="msgbody" style="background: #00CF8C">{{item.msg}}<br><span style="float: right;font-size: 9px;margin-top: 5px">{{item.time}}</span></p>
              </div>
              <div style="padding: 5px;text-align: left" v-else>
                <p class="msgsender item-color-sell">{{username}}</p>
                <div v-if="item.type==2" style="padding: 10px">
                  <img :src="item.msg" style="max-width: 100%;max-height: 600px;border-radius: 5px">
                </div>
                <p v-else class="msgbody" style="background: #F5465D">{{item.msg}}<br><span style="float: right;font-size: 9px;margin-top: 5px">{{item.time}}</span></p>
              </div>
            </div>
          </div>
          <div style="width: 100%;height: 50px;line-height: 50px;border-top: 1px #d9d9d9 solid;background: #fff">
            <div style="position: relative;width: 100%">
              <div style="padding-top: 7px;padding-left: 7px;padding-right: 130px;">
                <wd-input style="width: 100%;padding-left:10px;border: 1px #333 solid;" v-model="msg"></wd-input>
              </div>
              <div style="width: 120px;position: absolute;text-align: right;right: -5px;top:-5px;padding-top: 3px">
                <div style="width: 40px;float: left;margin-top: 7px" @click="dialogVisible=true">
                  <el-icon class="el-icon-upload" style="font-size: 30px" />
                </div>
                <wd-button style="width: 60px;margin-right: 10px;height: 30px;font-size: 11px" type="success" size="mini" @click="sendMsg">{{this.$LANG.sendmessage}}</wd-button>
              </div>

            </div>
          </div>
      </div>

    </el-row>
    <wd-popup v-model="dialogVisible" position="bottom" style="padding: 20px;" closable >

      <p style="margin-top:20px;font-size: 12px;font-family: Source Han Sans CN;font-weight: 400;line-height: 17px;color: #9AA4AF;text-align: left;width: 100%;float: left">
        Tips：每次只能上传一张图片，只支持png,jpg,jpeg格式，且不能大于1M。</p>

      <wd-upload
              v-model="fileList"
              :limit="1"
              :action=this.$IMG_UPLOAD_URL
              @exceed="handleExceed"
              @success="handleSuccess"
              @fail="handleError"
              @remove="handleRemove"
              style="width: 100%;float: left"
      ></wd-upload>

      <el-button type="primary" @click="sendImg" style="width: 100%;background: linear-gradient(130deg, #1188E3 0%, #1E56A0 100%);border-radius: 8px;border:none;margin-top: 30px;
    margin-bottom: 20px;">保存</el-button>


    </wd-popup>
    <audio controls="controls" hidden src="https://downsc.chinaz.net/Files/DownLoad/sound1/202202/15370.mp3" ref="audio"></audio>
  </div>

</template>
<style lang="scss" scoped>
@import "@/style/variable.scss";

  .el-aside {
    color: #333;
  }

  .el-card{
    margin-bottom: 20px;
  }

  .cellimg{
    width: 40px;height: 40px;float: left
  }

  .line{
    display: block;height: 1px;width: 100%;margin: 10px 0;background-color: #d9d9d9;position: relative;
  }
  .box-card {
    width: 100%;
    background-color: $bg-color-qian;
    margin-bottom: 30px;
    border:none;
  }

  .my-cell{
    width: 100%;text-align: left;height: 30px;line-height: 30px;color: #fafafa
  }

  .my-cell .icon{
    float: right;line-height: 30px;
  }

  .card-label {
    float: left;
    font-size:26px;
    font-weight: bold;
    color: $text-color;
  }
.msgsender{
  font-size: 14px;margin: 0px
}
.msgbody{
  border-radius: 5px;padding: 10px;color: #fafafa;width: fit-content;margin-top: 5px;padding-bottom: 20px;
}
</style>


<script>

  import { Api } from '@/api/interface' // 引入接口
  // import { TokenApi } from '@/api/interface' // 引入接口
  export default {
    data() {
      return {
        activeTab: 'first',
        timer:'',
        userList:[],
        hisList:[],

        msgList: [],
        fileList:[],
        msg:'',
        msg_id:0,
        img:'',
        user_id: 1,
        username:'',
        dialogVisible:false
      }
    },
    components: {},
    methods:{
      handleExceed ({ files, fileList }) {
        this.$toast.warning('超出最大个数限制')
      },
      handleSuccess ({ response, file, fileList }) {
        // this.imgs.push(response.data)
        this.img = response.data
        console.log("response.data:" + this.fileList[0].response.data)
        console.log(this.fileList)
      },
      handleRemove ({ file, fileList }) {
        this.$toast.success('删除成功')
        console.log(this.fileList)
      },
      handleError ({ error, file, fileList }) {
        this.$toast.error('上传失败')
      },
      handleChange(value) {
        console.log(value);
      },
      tabClick(tab, event) {
        console.log(tab.name);
      },
      gotochat(val) {
        this.user_id = val.user_id
        this.username = val.user_name
        this.msgList = [];
        //通知服务器删除已读
        this.$socket.emit('readMsg',val.user_id);
        this.$socket.emit("historyMsg",this.user_id);
        for(let i=0;i<this.userList.length;i++){
          if(this.userList[i].user_id == val.user_id){
            this.userList.splice(i,1);
            break;
          }
        }
      },

      sendMsg(){
        if(!this.msg || this.msg == ""){
          this.$message({
            message: 'Input empty',
            type: 'error'
          });
          return;
        }
        this.msg_id = this.msg_id + 1;
        let postData = {
          id:this.msg_id,
          type:1,
          uid:'system',
          fromUserName:"system",
          toUserName:this.username,
          toid:this.user_id,
          msg:this.msg
        }

        this.$socket.emit("sendMsg",JSON.stringify(postData));
        this.msg = '';
      },
      sendImg(){
        this.dialogVisible=false;
        if(!this.img || this.img == ""){
          this.$message({
            message: 'Input empty',
            type: 'error'
          });
          return;
        }
        this.msg_id = this.msg_id + 1;
        let postData = {
          id:this.msg_id,
          type:2,
          uid:'system',
          fromUserName:"system",
          toUserName:this.username,
          toid:this.user_id,
          msg:this.img
        }
        this.$socket.emit("sendMsg",JSON.stringify(postData));
        this.img = '';
      },
    },
    created() {
      // this.UserMsgListByRedis();
      // this.getHis();
      // this.getMsg();
      let that = this;
      document.onkeypress = function(e) {
        var keycode = document.all ? event.keyCode : e.which;
        if (keycode == 13) {
          that.sendMsg();
          return false;
        }
      };
    },
    updated(){
      // 聊天定位到底部
      let ele = document.getElementById('chatdiv');
      ele.scrollTop = ele.scrollHeight;
    },
    mounted() {
      // this.timer = setInterval(this.UserMsgListByRedis, 3000);
      this.$socket.emit("onLine",'system');
    },
    beforeDestroy() {
      // clearInterval(this.timer);
    },
    sockets: {
      //查看socket是否渲染成功
      connect() {
        console.log("链接成功");
      },
      online(res){
        this.$socket.emit("getMsg",'systemList');
        this.$socket.emit("smembers",'systemList');
      },
      disconnect(){
        console.log("断开链接");
      },//检测socket断开链接
      reconnect(){
        console.log("重新链接");
        this.$socket.emit("onLine",'system');

      },


      historyMsg(data) {
        let res = JSON.parse(data);
        if(res.type == 2){
          res.msg = this.$IMG_URL + res.msg
        }
        this.msgList.push(res);
        console.log("historyMsg:"+res);
      },

      receive(data) {
        let res = JSON.parse(data);
        this.msg_id = res.id;
        console.log("getMsg:"+res);
        if(res.type == 2){
          res.msg = this.$IMG_URL + res.msg
        }
        this.msgList.push(res);
      },


      //客户端接收后台传输的socket事件
      message(data) {
        let res = JSON.parse(data);
        console.log(res);

        if(res.uid == this.user_id){
          this.$socket.emit('readMsg',res.uid);
          if(res.type == 2){
            res.msg = this.$IMG_URL + res.msg
          }
          this.msgList.push(res);
          return
        }

        let has = false;
        for(let i=0;i<this.userList.length;i++){
          if(this.userList[i].user_id == res.uid){
            this.userList[i].count += 1;
            has = true;
            break;
          }
        }
        if(!has){
          this.userList.push({user_id:res.uid,user_name:res.fromUserName,count:1})
        }
        this.$refs.audio.currentTime = 0; //从头开始播放提示音
        this.$refs.audio.play(); //播放
      },

      smembers(data){
        let res = JSON.parse(data);
        this.hisList = [];
        for(let i=0;i<res.length;i++){
          let msg = res[i].split("_,_");
          this.hisList.push({user_id:msg[0],user_name:msg[1]});
        }
        console.log("smembers:"+data);
      }

    }
  };
</script>
