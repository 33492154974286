<template>
  <div>

    <wd-navbar :title="this.$LANG.wodetuijian">
      <span slot="left" @click="goback">
        <wd-icon name="arrow-left" />
      </span>
    </wd-navbar>


    <el-container>
      <!--    <el-header style="text-align: right; font-size: 12px">-->
      <!--      <label style="float: left;font-size:large;font-weight: bold">{{this.$LANG.wodetuijian}}{{daili}}</label>-->
      <!--    </el-header>-->

      <el-main>
        <el-card shadow="hover">
          <el-row>
            <label style="float: left;color: rgb(112, 122, 138)">{{this.$LANG.leijituijian}}</label>
          </el-row>
          <el-row style="margin-top: 10px">
            <label style="float: left;font-size:large;font-weight: bold">{{count}}<span style="font-size:small;margin-left: 10px">{{this.$LANG.man}}</span></label>
          </el-row>
        </el-card>
        <el-card shadow="hover">
          <el-row>
            <label style="float: left;color: rgb(112, 122, 138)">{{this.$LANG.leijishouyi}}（USDT）</label>
          </el-row>
          <el-row style="margin-top: 10px">
            <label style="float: left;font-size:large;font-weight: bold">{{amt}}<span style="font-size:small;margin-left: 10px">USDT</span></label>
          </el-row>
        </el-card>
        <el-card shadow="hover">

              <el-row>
                <label style="float: left;color: rgb(112, 122, 138)">{{this.$LANG.tuijianlink}}</label>
              </el-row>
              <el-row style="margin-top: 30px">
                <label style="float: left;font-size:large;font-weight: bold">{{url}}</label>
              </el-row>

          <el-row style="margin-top: 20px;">
            <div class="qrcode" ref="qrCodeUrlOMNI" id="OMNI" style="border: 1px solid black;width: 100px;margin: auto"></div>
          </el-row>
          <el-row  style="margin-top: 20px;">
            <el-col :span="12">
              <el-button type="success" style="margin-left:10px;float: left" size="small" @click="copyUrl" class="copy-btn" :data-clipboard-text="user_id">{{this.$LANG.copycode}}</el-button>
            </el-col>
            <el-col :span="12">
              <el-button type="success" style="margin-right:10px;float: right" size="small" @click="copyUrl" class="copy-btn" :data-clipboard-text="url">{{this.$LANG.copylink}}</el-button>
            </el-col>
          </el-row>
        </el-card>


      </el-main>
    </el-container>
  </div>
</template>


<script>

  import { TokenApi } from '@/api/interface'

  import QRCode from 'qrcodejs2';
  export default {
    data() {
      return {
        drawer: false,
        count:0,
        user_id:null,
        amt:0.0000,
        url:'',
        daili:''
      }
    },
    components: {},
    methods:{
      goback(){
        this.$router.go(-1)
      },

      UserRecommend(){
        TokenApi('/userRecommend',{},this,'get').then(data => {
          if(data.code==0){
            this.user_id = data.data.user.code;
            this.url = this.$WEB_URL+'#/index?key='+data.data.user.code;
            this.count = data.data.RecommendCount;
            this.amt = data.data.RecommendAmt;
            let amtList = data.data.RecommendAmt
            if(amtList.length>0){
              for(let i =0;i<amtList.length;i++){
                this.amt += amtList[i].value
              }
            }else {
              this.amt = 0;
            }
            if(data.data.user.is_agent > 0){
              this.daili = " -- DL"
            }
            this.creatCode();
          }
          console.log(data.data)
        })
      },
      creatCode() {
        new QRCode(this.$refs.qrCodeUrlOMNI, {
          text: this.url, // 需要转换为二维码的内容
          width: 100,
          height: 100,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H
        })
      },
      // 复制链接
      copyUrl() {
        let _this = this;
        let clipboard = new Clipboard(".copy-btn"); // 这里括号里填写上面点击事件绑定的class名
        clipboard.on("success", e => {
          // 复制成功，提示根据自己项目实际使用的UI来写
          _this.$message.success("Copy success")
          // 释放内存
          clipboard.destroy();
        });
        clipboard.on("error", e => {
          // 不支持复制，提示根据自己项目实际使用的UI来写
          _this.$message.error("The browser does not support automatic replication")
          // 释放内存
          clipboard.destroy();
        });
      },
    },
    created() {
      //默认菜单
      localStorage.setItem("activeIndex","4");
      this.UserRecommend();
    }
  };
</script>
