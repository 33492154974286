<template>
  <div>
<div style="width: 100%;padding-top: 15px">
        <el-tabs style="padding:0px 5px;" class="bottombox" v-model="activeTab">
          <el-tab-pane :label="$LANG.article" name="first">
            <div class="list-container">
              <div class="list">
                <div v-for="(item, index) in articleData" :key="index" class="list-item" @click="gotoDetail(item)">
                  <div style="height: 100px;border-bottom: 1px solid #eee;padding: 0px 10px;padding-bottom: 10px" class="content" >
                    <div style="width: 65%;float: left">
                      <div style="color: rgba(0,0,0,0.85); font-size: 17px;text-align: left;padding: 5px 0px">{{ item.title }}</div>
                      <div style="color: rgba(0,0,0,0.25); font-size: 14px;text-align: left">{{ item.createTime.substr(10,20) }} </div>
                    </div>
                    <div style="width: 35%;float: left">
                      <img :src="item.cover" style="width: 120px;height: 90px;border-radius: 8px"/>
                    </div>
                  </div>
                </div>
              </div>
              <div style="width: 100%;" v-if="loading">
                <wd-loading style="margin: auto"></wd-loading>
              </div>
              <el-empty description="empty data" style="width: 50%;margin: auto" v-if="count1<1"></el-empty>
            </div>
          </el-tab-pane>
          <el-tab-pane :label="$LANG.noticemsg" name="second">
            <div class="list-container">
              <div class="list">
                <div v-for="(item, index) in noticeData" :key="index" class="list-item">
                  <div style="height: 60px;border-bottom: 1px solid #f9f9f9;padding: 0px 15px" class="content" @click="notice_content(index)">
                    <div style="width: 100%">
                      <div style="color: rgba(0,0,0,0.85); font-size: 17px;text-align: left;padding: 5px 0px">{{ item.title }}</div>
                      <div style="color: rgba(0,0,0,0.25); font-size: 14px;text-align: left">{{ item.createTime }} </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="width: 100%;" v-if="loading">
                <wd-loading style="margin: auto"></wd-loading>
              </div>
              <el-empty description="empty data" style="width: 50%;margin: auto" v-if="count2<1"></el-empty>
            </div>
          </el-tab-pane>
        </el-tabs>
</div>
    <wd-popup v-model="drawer" position="bottom" style="padding: 20px" closable>
      <div  id="noticeContent"></div>
    </wd-popup>
    <Footer/>
  </div>
</template>
<style scoped>
  .content, .title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .content {
    justify-content: flex-start;
  }
  .title {
    justify-content: space-between;
  }
  .title-tip {
    color: rgba(0, 0, 0, 0.25);
    font-size: 12px;
  }
  input{
    background: #fafafa;color: black;
  }
</style>

<script>

  import { Api } from '@/api/interface' // 引入接口
  import Footer from "@/components/Footer";
  export default {
    data() {
      return {
        activeTab:"first",
        drawer: false,
        loading1: false,
        loading: false,
        count1:1,
        count2:1,
        articleData:[],
        noticeData:[],
        notice_index:0,
        ncontent:0,
      }
    },
    components: {
      Footer
    },
    methods:{
      gotoDetail(item){
        this.$router.push({ path: "detail?aid="+item.id});
      },
      notice_content(index){
        // this.notice_index = index;
        this.drawer = true;
        this.ncontent = this.noticeData[index].content
        document.getElementById("noticeContent").innerHTML = this.ncontent
      },
      article () {
        this.loading1 = true
        Api('/no_token/articleList',this.listForm,this,'get').then(data => {
          if(data.code==0){
            this.count1 = data.data.total
            if(data.data.records.length){
              this.articleData = data.data.records;
            }
          }
          this.loading1 = false
        })
      },

      notice () {
        this.loading = true
        Api('/no_token/noticeList',this.listForm,this,'get').then(data => {
          if(data.code==0){
            this.count2 = data.data.total
            if(data.data.records.length){
              this.noticeData = data.data.records;
            }
          }
          this.loading = false
        })
      },

    },
    created() {
      //默认菜单
      // this.loading = true
      // this.getData(this.listForm);
      this.article()
      this.notice()
    },
  };
</script>
<style lang="scss" >
  @import "@/style/variable.scss";

  .mine-exchange {
    min-height: 100vh;
    background-color: $bg-color;
  }
  .header {
    background: $bg-color-qian;
  }
</style>
