import Vue from 'vue'
import Router from 'vue-router'
import Header from '@/components/Header'
import Footer from '@/components/Footer'

import Login from '@/views/user/Login'
import Register from '@/views/user/Register'
import Me from '@/views/user/Me'
import Asset from '@/views/user/Asset'

import UsdtIn from '@/views/trade/UsdtIn'
import UsdtOut from '@/views/trade/UsdtOut'
import AccountLog from '@/views/user/AccountLog'
import UsdtOutLog from '@/views/user/UsdtOutLog'
import Recommend from '@/views/user/Recommend'

import Index from '@/views/Index'
import Lang from '@/views/Lang'

import NoticeLog from '@/views/article/NoticeLog'
import Detail from '@/views/article/Detail'
import Rate from '@/views/user/Rate'

import Chat from '@/views/msg/Chat'
import MsgList from '@/views/msg/MsgList'
import SysChat from '@/views/msg/SysChat'
import MsgPhone from '@/views/msg/MsgPhone'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'Index',
      component: Index
    },{
      path: '/index',
      name: 'Index',
      component: Index
    },{
      path: '/lang',
      name: 'Lang',
      component: Lang
    },
    {
      path: '/header',
      name: 'Header',
      component: Header
    },{
      path: '/footer',
      name: 'Footer',
      component: Footer
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },{
      path: '/me',
      name: 'Me',
      component: Me
    },{
      path: '/asset',
      name: 'Asset',
      component: Asset
    },
    {
      path: '/register/:code',
      name: 'Register',
      component: Register
    },
    {
      path: '/usdtin',
      name: 'UsdtIn',
      component: UsdtIn
    },
    {
      path: '/usdtout',
      name: 'UsdtOut',
      component: UsdtOut
    },
    {
      path: '/accountlog',
      name: 'AccountLog',
      component: AccountLog
    },
   {
      path: '/usdtoutlog',
      name: 'UsdtOutLog',
      component: UsdtOutLog
    },
    {
      path: '/recommend',
      name: 'Recommend',
      component: Recommend
    },
    {
      path: '/notice',
      name: 'NoticeLog',
      component: NoticeLog
    },{
      path: '/detail',
      name: 'Detail',
      component: Detail
    },{
      path: '/rate',
      name: 'Rate',
      component: Rate
    },
    {
      path: '/chat',
      name: 'Chat',
      component: Chat
    },{
      path: '/msg_win',
      name: 'MsgList',
      component: MsgList
    },{
      path: '/msg_phone',
      name: 'MsgPhone',
      component: MsgPhone
    },
    {
      path: '/sys_chat/:uid/:username/',
      name: 'SysChat',
      component: SysChat
    },
  ]
})
