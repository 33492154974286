<template>
  <div>

    <div id="app">
      <router-view/>
    </div>
<!--    <Footer></Footer>-->

  </div>
</template>

<!--<script>-->
<!--  export default {-->
<!--    name: 'App',-->
<!--    data(){-->
<!--      return {-->
<!--        token:localStorage.getItem("accessToken"),-->
<!--        height:{minHeight:(document.documentElement.clientHeight-61-297)+"px"}-->
<!--      }-->
<!--    },-->
<!--    methods:{-->
<!--      /**-->
<!--       * 从router-view这个子组件中返回的值，作为登录标记传给另外的组件使用-->
<!--       * 在组件使用时通过@childFn="parentFn"绑定事件接收函数-->
<!--       * @param token-->
<!--       */-->
<!--      parentFn(token){-->
<!--        this.token = token-->
<!--        console.log('from childFn:'+token)-->
<!--      }-->
<!--    }-->
<!--    // updated() {-->
<!--    //   this.height = document.documentElement.clientHeight-61-297;-->
<!--    //   console.log(this.height)-->
<!--    // }-->

<!--  }-->
<!--</script>-->

<script>
  window.onload = function() {
    // this.$toast.error("touchstart");
    // alert("error")
    // console.log("test")

    // document.addEventListener('touchstart', function(event) {
    //   if (event.touches.length > 1) {
    //     event.preventDefault();
    //   }
    //   this.$toast.success("touchstart");
    // });
    // document.addEventListener('gesturestart', function(event) {
    //   event.preventDefault();
    // });

    // 禁用双指放大
    document.documentElement.addEventListener('touchstart', function (event) {
      // alert("here:"+event.touches.length)
      if (event.touches.length > 1) {
        event.preventDefault();
      }
    }, {
      passive: false
    });

    // 禁用双击放大
    var lastTouchEnd = 0;
    document.documentElement.addEventListener('touchend', function (event) {
      var now = Date.now();
      if (now - lastTouchEnd <= 400) {
        event.preventDefault();
      }
      lastTouchEnd = now;
    }, {
      passive: false
    });
  };
  // 禁用缩放
  // function addMeta() {
  //   $('head').append('<meta name="viewport" content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no" />');
  // }
  // setTimeout(addMeta, 3000);
</script>
<style>
  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #000;
    /*margin-top: 60px;*/
    /*background:url("assets/quickBuyBg.png") ;*/
  }
  body{
    margin: 0px;
    background: #FFF;
  }
  .page_width{
    width: 100%;
  }

</style>
