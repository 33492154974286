
<template>
<div>
  <wd-navbar :title="this.$LANG.login">

      <span slot="left" @click="gotoIndex">
        <wd-icon name="arrow-left" />Index
      </span>
    </wd-navbar>
  <el-row type="flex" class="row-bg" justify="center"  style="padding-top: 100px">

    <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm"  class="demo-ruleForm" style="width: 80%;">
      <!-- <h3>{{this.$LANG.login}}</h3> -->
      <el-form-item prop="username" :label="this.$LANG.logac">
        <el-input v-model="ruleForm.username" :placeholder="this.$LANG.logacinfo"></el-input>
      </el-form-item>

      <el-form-item :label="this.$LANG.password" prop="pass">
        <el-input type="password" v-model="ruleForm.pass" autocomplete="off" :placeholder="this.$LANG.inputpswpl"></el-input>
      </el-form-item>

      <el-button-group style="width: 100%;margin-top: 20px">
        <wd-button type="primary" @click="submitForm('ruleForm')" style="width: 100%;border-radius: 5px">{{this.$LANG.login}}</wd-button>
      </el-button-group>

      <el-form-item>
        <el-link type="primary" href="/#/register?rc=2EF6DB">{{this.$LANG.nonaccount}}&nbsp;&nbsp;</el-link>
        <span type="info" @click="show_kefu=true">&nbsp;&nbsp;{{this.$LANG.forgetpsw}}</span>
      </el-form-item>


    </el-form>

  </el-row>
  <wd-popup v-model="show_kefu" position="bottom"  closable>
    <div style="width: 100%;">
      <h4 style="line-height: 0px;padding-left: 15px">{{this.$LANG.kefu}}</h4>
    </div>
    <div style="padding: 20px;border-top: 1px gray solid;">
      <p>{{$LANG.kefuxgmm}}</p>
      <p>{{$LANG.liangjie}}</p>
      <br>
      <el-button type="primary" @click="gotoKefu()" style="width: 100%;background: rgb(2, 192, 118);border:rgb(2, 192, 118)" >{{this.$LANG.kefu}}</el-button>
      <br>
    </div>

  </wd-popup>
</div>
</template>

<script>
  import { Api } from '@/api/interface' // 引入接口
  export default {
    name: "Login",
    data() {

      var validateUser = (rule, value, callback) => {
        if (value === '') {
          callback(new Error(this.$LANG.cannotempty));
        } else {
          callback();
        }
      };

      var validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error(this.$LANG.cannotempty));
        } else {
          callback();
        }
      };

      return {
        show_kefu:false,
        ruleForm: {
          pass: '',
          username: ''
        },
        bgdiv: {
          backgroundImage:'url(' + require('@/assets/login_bg.jpg') + ')',
          // backgroundImage:'linear-gradient(#517CF0,#769EF5)',
          backgroundRepeat:'no-repeat',
          backgroundSize: 'fixed'
        },
        rules: {
          pass: [
            { validator: validatePass, trigger: 'blur' }
          ],
          username: [
            { validator: validateUser, trigger: 'blur' }
          ]
        }
      };
    },
    methods: {
      gotoKefu(){
        window.open("https://jivo.chat/6nAxXJMvAA")
        // this.$router.push({ path: '/chat' });
        // window.open("https://dzjx7s6oau4g4.cloudfront.net/?c=2dc48dc25e53ce18&g=1&n=195769&l=jp")
      },
      gotoIndex(){
        this.$router.push({ path: '/index' });
      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let postData = {username: this.ruleForm.username,password:this.ruleForm.pass} // 请求参数
            Api('/no_token/login',postData).then(data => {
              if(data.code==0){
                localStorage.setItem('accessToken',data.data.access_token)
                // this.$message({
                //     message: 'success',
                //     type: 'success'
                // });

                //登录成功后通知父组件，让父组件通知其他组件
                // this.$emit('childFn', data.data);

                this.$router.push({ path: '/me' });
              }else{
                this.$toast.error(this.$LANG.pswerr);
              }
              console.log(data.data)
            })

          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      }
    }
  }
</script>

<style lang="scss">
@import "@/style/variable.scss";
.row-bg {
  min-height: 100vh;
  background-color: $bg-color;
}
.header {
  background: $bg-color-qian;
}
</style>
