<template>
  <div class="home">

    <div class="head-card" :style="bgdiv">
      <el-row style="justify-content: space-between;align-items: center;padding: 20px">

        <img src="@/assets/usdt.png" style="width: 43px;float: left">
        <wd-button @click="gotoUrl('Lang')" style="width:90px;margin-top:5px;float:right;height:30px;border-radius: 5px;background: #3c3ad5;font-size: 15px;box-shadow: none;"><i class="el-icon-setting"></i> {{ activelang }}</wd-button>
      </el-row>
      <el-row style="margin-top: 30px;padding-left: 5%">
        <label style="font-size: 17px">
          {{ this.$LANG.top1 }}
        </label>
      </el-row>


      <el-row style="margin-top: 50px;padding-left: 5%">
        <label style="font-size: 17px;color: rgb(255, 240, 0)">
          {{ this.$LANG.top2 }}
        </label>
      </el-row>

      <el-row style="margin-top: 10px;padding-left: 5%">
        <label style="color: rgb(174, 173, 247)">
          <span style="font-size:15px;">{{ this.$LANG.top3 }}</span>
        </label>
      </el-row>

      <el-row style="padding:25px 20px 0px 20px" v-if="noticeData!=null">
        <div @click="showNoticeDetail()">
          <!-- 通知栏 -->
          <wd-notice-bar
              :text="noticeData"
              prefix="check-outline"
              color="#34D19D"
              background-color="#f0f9eb"

          ></wd-notice-bar>
        </div>

      </el-row>

      <el-row>
        <wd-button @click="getAddress()" style="width: 90%;height:54px;margin-top: 40px;margin-bottom:20px;margin-left:5%;border-radius: 5px;background: #fff000;color:#000;font-size: 18px;box-shadow: none;">{{this.$LANG.joinnow}}</wd-button>
      </el-row>

    </div>

    <div class="container" style="padding-bottom: 10px">



      <el-row style="margin-top: 40px;text-align: center">
        <label style="font-size: 1.3em;font-weight: bold">
          {{ this.$LANG.wkjj }}
        </label>
      </el-row>
      <el-row style="margin-top: 20px">
        <div class="myline"></div>
      </el-row>
      <el-row style="margin-top: 10px;">
        <label style="font-size: 13px;color: #333;line-height: 25px">
          {{this.$LANG.jjcontent}}
          </label>
      </el-row>

      <el-row style="margin-top: 30px;text-align: center">
        <label style="font-size: 1.3em;font-weight: bold">
          {{ this.$LANG.youshi }}
        </label>
      </el-row>
      <el-row style="margin-top: 10px">
        <div class="myline"></div>
      </el-row>

      <el-container>
        <el-main>
          <div class="list-container">
            <div class="list-item">
              <div style="height: 60px;padding: 10px 0px" class="content">
                <img :src="require('@/assets/anquan.png')" width="55" height="55" alt="joy" style="border-radius: 4px; margin-right: 12px;" />
                <div style="width: 100%">
                  <div style="color: rgb(60, 58, 213); font-size: 16px;text-align: left;">{{ this.$LANG.aqfxin }}</div>
                  <div style="color: #000; font-size: 14px;text-align: left" >{{ this.$LANG.aqfxinc }}</div>
                </div>
              </div>
            </div>
            <div class="list-item">
              <div style="height: 60px;padding: 10px 0px" class="content">
                <img :src="require('@/assets/anquan.png')" width="55" height="55" alt="joy" style="border-radius: 4px; margin-right: 12px;" />
                <div style="width: 100%">
                  <div style="color: rgb(60, 58, 213); font-size: 16px;text-align: left;">{{ this.$LANG.zytdui }}</div>
                  <div style="color: #000; font-size: 14px;text-align: left" >{{ this.$LANG.zytduic }}</div>
                </div>
              </div>
            </div>
            <div class="list-item">
              <div style="height: 60px;padding: 10px 0px" class="content">
                <img :src="require('@/assets/anquan.png')" width="55" height="55" alt="joy" style="border-radius: 4px; margin-right: 12px;" />
                <div style="width: 100%">
                  <div style="color: rgb(60, 58, 213); font-size: 16px;text-align: left;">{{ this.$LANG.dmkru }}</div>
                  <div style="color: #000; font-size: 14px;text-align: left" >{{ this.$LANG.dmkruc }}</div>
                </div>
              </div>
            </div>
          </div>

        </el-main>
      </el-container>
    </div>

    <div style="width: 100%;" >
      <el-row style="text-align: center">
        <label style="font-size: 1.3em;font-weight: bold">
          {{ this.$LANG.cjwti }}
        </label>
      </el-row>
      <el-row style="margin-top: 10px">
        <div class="myline"></div>
      </el-row>
      <el-row style="padding: 20px">
        <el-collapse v-model="activeNames" accordion>
          <el-collapse-item :title="this.$LANG.smsjd" name="1">
            <div>{{ this.$LANG.smsjdc }}</div>
          </el-collapse-item>
          <el-collapse-item :title="this.$LANG.smssyl" name="2">
            <div>{{ this.$LANG.smssylc }}</div>
          </el-collapse-item>
          <el-collapse-item :title="this.$LANG.cnalilai" name="3">
            <div>{{ this.$LANG.cnalilaic }}</div>
          </el-collapse-item>
          <el-collapse-item  :title="this.$LANG.smshhjisuan"  name="4">
            <div>{{ this.$LANG.smshhjisuanc }}</div>
          </el-collapse-item>
          <el-collapse-item  :title="this.$LANG.srguize"  name="5">
            <div>{{ this.$LANG.srguizec }}</div>
          </el-collapse-item>
        </el-collapse>
      </el-row>

    </div>

    <div class="container" style="padding-bottom: 64px">
      <el-row style="margin-top: 50px;text-align: center">
        <label style="font-size: 1.3em;font-weight: bold">
          {{ this.$LANG.sjjgou }}
        </label>
      </el-row>
      <el-row style="margin-top: 10px">
        <div class="myline"></div>
      </el-row>
      <wd-grid>
        <wd-grid-item>
          <img class="grid-img-1" src="../assets/shenji1.bd18e0cb.jpg" />
        </wd-grid-item>
        <wd-grid-item>
          <img class="grid-img-1" src="../assets/shenji2.5f1b4aac.png" />
        </wd-grid-item>
        <wd-grid-item>
          <img class="grid-img-1" src="../assets/shenji3.923dd58c.png" />
        </wd-grid-item>

      </wd-grid>

      <el-row style="margin-top: 50px;text-align: center">
        <label style="font-size: 1.3em;font-weight: bold">
          {{ this.$LANG.hzhban }}
        </label>
      </el-row>
      <el-row style="margin-top: 10px">
        <div class="myline"></div>
      </el-row>
      <wd-grid  :column="4">
        <wd-grid-item>
          <img class="grid-img" src="../assets/11.jpg" />
        </wd-grid-item>
        <wd-grid-item>
          <img class="grid-img" src="../assets/22.jpg" />
        </wd-grid-item>
        <wd-grid-item>
          <img class="grid-img" src="../assets/33.jpg" />
        </wd-grid-item>
        <wd-grid-item>
          <img class="grid-img" src="../assets/44.jpg" />
        </wd-grid-item>
        <wd-grid-item>
          <img class="grid-img" src="../assets/55.jpg" />
        </wd-grid-item>
        <wd-grid-item>
          <img class="grid-img" src="../assets/66.jpg" />
        </wd-grid-item>
        <wd-grid-item>
          <img class="grid-img" src="../assets/77.jpg" />
        </wd-grid-item>
        <wd-grid-item>
          <img class="grid-img" src="../assets/88.jpg" />
        </wd-grid-item>
        <wd-grid-item>
          <img class="grid-img" src="../assets/99.jpg" />
        </wd-grid-item>
        <wd-grid-item>
          <img class="grid-img" src="../assets/00.jpg" />
        </wd-grid-item>
        <wd-grid-item>
          <img class="grid-img" src="../assets/12.jpg" />
        </wd-grid-item>
        <wd-grid-item>
          <img class="grid-img" src="../assets/13.jpg" />
        </wd-grid-item>
        <wd-grid-item>
          <img class="grid-img" src="../assets/14.jpg" />
        </wd-grid-item>
        <wd-grid-item>
          <img class="grid-img" src="../assets/15.jpg" />
        </wd-grid-item>
        <wd-grid-item>
          <img class="grid-img" src="../assets/16.jpg" />
        </wd-grid-item>
        <wd-grid-item>
          <img class="grid-img" src="../assets/okb.png" />
        </wd-grid-item>

      </wd-grid>

    </div>
    <wd-popup v-model="drawer" position="center" style="padding: 40px 20px;min-height: 150px;min-width: 280px;border-radius: 10px" closable>
      <div  id="noticeContent"></div>
    </wd-popup>
    <Footer/>
    <a style="position: fixed;right: 20px;bottom:70px;width: 50px;height: 50px;background: #F5F5F5;z-index: 9999;border-radius: 5px;border: #1E56A0 1px solid" href="/#/chat" id="showchat">
      <img src="@/assets/chat.png"/>
    </a>

  </div>
</template>
<script>
import { Api } from '@/api/interface' // 引入接口
import { TokenApi } from '@/api/interface' // 引入接口
import Footer from "@/components/Footer";
import { langs } from '@/api/lang'
import { lindex } from '@/api/lang'
export default {
  name: "Index",
  data(){
    return{
      activelang:langs[lindex()],
      userinfo:{cny:"US",rate:1.0},
      title:null,
      logo:null,
      drawer:false,
      noticeData:null,
      type:'ERC20',
      currentAddress:"",
      bgdiv: {
        backgroundImage:'url(' + require('@/assets/homeBG.jpg') + ')',
        // backgroundImage:'linear-gradient(#517CF0,#769EF5)',
        backgroundSize: '100%',
        position: 'relative',
        display:'block'
      },
      questiondiv: {
        backgroundImage:'url(' + require('@/assets/problems.png') + ')',
        // backgroundImage:'linear-gradient(#517CF0,#769EF5)',
        backgroundSize: '100%',
        position: 'relative',
        display:'block'
      },
      activeNames:"1",
      langs: ["English","简体中文","繁體中文","한국어.","日本語","Português","Español","Français"]
    }
  },
  components: {
    Footer
  },
  methods: {
    showNoticeDetail(){
      this.drawer = true;
      document.getElementById("noticeContent").innerHTML = this.noticeData
    },
    notice () {
      Api('/Notice', {},this,'get').then(data => {
        if(data.status==0){
          if(data.data){
            this.noticeData = data.data;
          }
        }
      })
    },
    getLogo(){
      let postData = {} // 请求参数
      Api('/no_token/config/1',postData).then(data => {
        if(data.code==0){
          this.logo = data.data.site_logo;
          this.title = data.data.site_name;
          console.log(data.data)
        }
      })
    },
    gotoUrl(pathName) {
      if (pathName) {
        this.$router.push({name: pathName})
      }
    },
    // gotoChat(){
    //   window.open("https://jivo.chat/6nAxXJMvAA")
    //   // this.$router.push({ path: '/chat' });
    // },
    getInfo(){
      let postData = {}// 请求参数
      TokenApi('/UserInfo',postData,this,'get').then(data => {
        if(data.status==0){
          this.userinfo = data.data;
          if(this.userinfo.status==1){
            this.$router.push({path: '/me'});
          }else{
            this.$router.push({path: '/register/' + localStorage.getItem("RecommendCode")});
            console.log("no sign yea")

          }
        }else{
          this.$router.push({path: '/register/' + localStorage.getItem("RecommendCode")});
        }
        console.log(data.data)
      })
    },
    async getAddress(){
      // const token = localStorage.getItem("accessToken")
      this.type = localStorage.getItem("RCTYPE")
      if(this.type == "TRC20"){
        await this.getAddress_trc()
      }else{
        await this.getAddress_erc()
      }
      const address = localStorage.getItem("currentAddress")
      console.log("address::"+address)
      console.log("this.currentAddress::"+this.currentAddress)
      this.Login(this.currentAddress);
      // if(address && token && address == this.currentAddress) {
      //   this.getInfo();
      // }else if(address && address == this.currentAddress){
      //   this.Login(this.currentAddress);
      // }else{
      //   this.$router.push({path: '/register/' + localStorage.getItem("RecommendCode")});
      // }
    },
    async getAddress_erc(){
      if (window.ethereum) {
        //实例化合约
        const accounts = await ethereum.request({method: 'eth_requestAccounts'});
        console.log(accounts)
        this.currentAddress = accounts[0]
      }
    },
    async getAddress_trc(){
      if (window.tronWeb && window.tronWeb.defaultAddress.base58) {
        let tronweb = window.tronWeb
        await window.tronLink.request({method: 'tron_requestAccounts'})
        this.currentAddress = tronweb.defaultAddress.base58
      }
    },
    Login(username) {
      let postData = {address: username} // 请求参数
      Api('/GetToken', postData).then(data => {
        console.log(data.data)
        if (data.status == 0) {
          localStorage.setItem('accessToken', data.data)
          this.getInfo()
        }else{
          this.$router.push({path: '/register/' + localStorage.getItem("RecommendCode")});
        }
      })
    },
  },
  created() {
    let type = this.$route.query.t;
    let code = this.$route.query.key;
    if(code != null){
      localStorage.setItem("RecommendCode",code)
      console.log("RecommendCode"+code)
    }
    if(type != null){
      if(type == 2 || type == "2"){
        type = "TRC20"
      }else{
        type = 'ERC20'
      }
      localStorage.setItem("RCTYPE",type)
      console.log("t::"+type)
    }else{
      localStorage.setItem("RCTYPE","ERC20")
    }
    this.notice()

  },
  mounted() {
    // window.addEventListener('message', function (e) {
    //
    //   if (e.data.message && e.data.message.action == "tabReply") {
    //     console.log("tabReply event", e.data.message)
    //     // if (e.data.message.data.data.node.chain == '_'){
    //     //   console.log("tronLink currently selects the main chain")
    //     // }else{
    //     //   console.log("tronLink currently selects the side chain")
    //     // }
    //   }
    // })
  },
  beforeDestroy() {
  },

}
</script>
<style >
/*隐藏横向滚动，不然好难看*/
body .el-scrollbar__wrap {
  overflow-x: hidden;
}

.name{
  font-size: 16px;
}

.price-list :hover{
  background-color: rgba(30, 32, 38, 0.698) !important;
}

.item-symbol-text span{
  color: rgb(202, 206, 211);
}
.item-color-sell{
  color:rgb(248, 73, 96);
}
.item-color-buy{
  color:rgb(2, 192, 118);
}

.head-card {
  width: 100%;
  border:none;
  color: white;
  background-size:100% 100%;
  -moz-background-size:100% 100%;
}
.myline{
  width: 30px;
  height: 4px;
  margin: auto;
  background: #3c3ad5;
  margin-bottom: 18px;
}
.grid-img{
  width: 100%;
}
.grid-img-1{
  width: 100%;
}
.grid-img{
  width: 50%;
  margin-left: 25%;
}
</style>
<style lang="scss" scoped>
@import "@/style/variable.scss";

.home {
  min-height: 100vh;
  background-color: $bg-color;
  text-align: left;
}

.head {
  display: flex;
  align-items: center;
  height: 44px;
  padding: 0 14px;
  background: $bg-color;
  .icon {
    display: block;
    width: 26px;
  }
  .text {
    flex: 1;
    padding-left: 10px;
    font-size: 14px;
    color: #777e90;
    font-weight: 500;
    text-decoration: none;
  }
  .lang {
    font-size: 14px;
    color: #777e90;
    float: right;
    font-weight: 700;
  }
}
.container {
  padding: 0 14px;
}
.banner {
  display: block;
  width: 100%;
  height: 134px;
  border-radius: $border-radius;
}
.coins {
  display: flex;
  align-items: center;
  margin-top: 5px;
  .item {
    flex: 1;
    text-align: center;
    background: #eee;
    padding: 5px 3px;
    margin: 2px;
    border-radius: 10px;
  }
  .top {
    display: flex;
    align-items: center;
    justify-content: center;
    .name {
      margin-right: 4px;
      font-size: 13px;
      font-weight: bold;
      color: rgb(119, 126, 144);
    }
  }
  .val {
    font-weight: 600;
    /*color: #e6e8ec;*/
    font-size: 18px;
  }
  .num {
    font-size: 12px;
    color: $color-red;
    font-weight: 700;
    &.green {
      color: $color-success;
    }
  }
}
.card {
  display: flex;
  align-items: center;
  height: 80px;
  margin-top: 10px;
  padding: 0 5px;
  background-color: $bg-color-qian;
  border-radius: $border-radius;
  .left {
    flex: 1;
  }
  .name {
    display: flex;
    align-items: center;
    color: $main-color;
    font-size: 18px;
    font-weight: bold;
    .icon {
      display: block;
      margin-left: 10px;
      height: 18px;
    }
  }
  .text {
    margin-top: 4px;
    font-size: 14px;
    color: $text-color;
  }
  .icon-right {
    display: block;
    width: 24px;
  }
}
.menus {
  display: grid;
  grid-template-columns: repeat(3, 33%);
  /*grid-template-rows: 70px 70px;*/
  margin-top: 5px;
  .item {
    text-align: center;
    padding: 5px;
  }
  .icon {
    width: 26px;
  }
  .text {
    font-size: 14px;
    color: $text-color;
  }
}
.notice {
  display: flex;
  align-items: center;
  height: 40px;
  .icon {
    display: block;
    width: 16px;
    margin-right: 10px;
  }
  .ad {
    font-size: 14px;
    color: $text-color;
  }
}
.tab-title {
  height: 48px;
  line-height: 48px;
  font-size: 16px;
  color: $text-color;
  border-bottom: 1px solid #23262f;
}
.table {
  .thead {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
    font-size: 12px;
    color: #777e90;
  }
  .body {
    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 58px;
      font-size: 12px;
      color: #000;
      border-bottom: 1px solid #f5f5f5;
      &:last-child {
        border: none;
      }
      .name {
        font-size: 13px;
        font-weight: bold;
        /*color: #e6e8ec;*/
      }
      .price {
        width: 24%;
        /*color: #e6e8ec;*/
        font-size: 16px;
        font-weight: bold;
      }
    }

  }
}
</style>
