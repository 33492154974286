<template>
<div>
    <wd-navbar :title="user_id" style="background: rgba(30, 32, 38, 0.9);position: fixed;top: 0px;">
      <span slot="left" @click="goback">
        <wd-icon name="arrow-left" />
      </span>
    </wd-navbar>
    <div style="width: 100%;height: 100%;margin-bottom: 50px;overflow-y: scroll;margin-top: 44px" id="chatdiv">
        <div v-for="(item, index) in msgList" :key="index">
            <div style="padding: 5px;text-align: left" v-if="item.uid == 'system'">
                <p class="msgsender item-color-buy">System</p>
                <div v-if="item.type==2" style="padding: 10px">
                    <img :src="item.msg" style="max-width: 100%;max-height: 600px;border-radius: 5px">
                </div>
                <p v-else class="msgbody" style="background: #00CF8C">{{item.msg}}<br><span style="float: right;font-size: 9px;margin-top: 5px">{{item.time}}</span></p>
            </div>
            <div style="padding: 5px;text-align: left" v-else>
                <p class="msgsender item-color-sell">{{username}}</p>
                <div v-if="item.type==2" style="padding: 10px">
                    <img :src="item.msg" style="max-width: 100%;max-height: 600px;border-radius: 5px">
                </div>
                <p v-else class="msgbody" style="background: #F5465D">{{item.msg}}<br><span style="float: right;font-size: 9px;margin-top: 5px">{{item.time}}</span></p>
            </div>
        </div>
    </div>
    <div style="width: 100%;height: 50px;line-height: 50px;position: fixed;bottom: 0px;left: 0px;border-top: 1px #d9d9d9 solid;background: #fff">
        <div style="position: relative;width: 100%">
            <div style="padding-top: 7px;padding-left: 7px;padding-right: 130px;">
                <wd-input style="width: 100%;padding-left:10px;border: 1px #333 solid;" v-model="msg"></wd-input>
            </div>
            <div style="width: 120px;position: absolute;text-align: right;right: -5px;top:-5px;padding-top: 3px">
                <div style="width: 40px;float: left;margin-top: 7px" @click="dialogVisible=true">
                    <el-icon class="el-icon-upload" style="font-size: 30px" />
                </div>
                <wd-button style="width: 60px;margin-right: 10px;height: 30px;font-size: 11px" type="success" size="mini" @click="sendMsg">{{this.$LANG.sendmessage}}</wd-button>
            </div>

        </div>
    </div>

    <wd-popup v-model="dialogVisible" position="bottom" style="padding: 20px;" closable >

            <p style="margin-top:20px;font-size: 12px;font-family: Source Han Sans CN;font-weight: 400;line-height: 17px;color: #9AA4AF;text-align: left;width: 100%;float: left">
                    Tips：每次只能上传一张图片，只支持png,jpg,jpeg格式，且不能大于1M。</p>

                <wd-upload
                        v-model="fileList"
                        :limit="1"
                        :action=this.$IMG_UPLOAD_URL
                        @exceed="handleExceed"
                        @success="handleSuccess"
                        @fail="handleError"
                        @remove="handleRemove"
                        style="width: 100%;float: left"
                ></wd-upload>

            <el-button type="primary" @click="sendImg" style="width: 100%;background: linear-gradient(130deg, #1188E3 0%, #1E56A0 100%);border-radius: 8px;border:none;margin-top: 30px;
    margin-bottom: 20px;">保存</el-button>


    </wd-popup>

</div>
</template>

<script>
    import { Api } from '@/api/interface' // 引入接口

    export default {
        data() {
            return {
                msgList: [],
                fileList:[],
                timer:null,
                msg:'',
                msg_id:0,
                img:'',
                user_id: 1,
                username:'',
                dialogVisible:false
            }
        },
        methods: {
            handleExceed ({ files, fileList }) {
                this.$toast.warning('超出最大个数限制')
            },
            handleSuccess ({ response, file, fileList }) {
                // this.imgs.push(response.data)
                this.img = response.data
                console.log("response.data:" + this.fileList[0].response.data)
                console.log(this.fileList)
            },
            handleRemove ({ file, fileList }) {
                this.$toast.success('删除成功')
                console.log(this.fileList)
            },
            handleError ({ error, file, fileList }) {
                this.$toast.error('上传失败')
            },
            handleChange(value) {
                console.log(value);
            },
            goback() {
              window.close();
                // this.$router.go(-1)
            },
            getMsg(){
                let postData = {user_id:this.user_id}// 请求参数
                Api('UserMsgBySys',postData,this).then(data => {
                    if(data.status==0){
                        var res = data.data.data
                        if(res){
                            let Base64 = require('js-base64').Base64
                            for(var i=0;i<res.length;i++){
                                res[i].msg = Base64.decode(res[i].msg)
                            }
                            this.msgList = res;
                        }

                    }
                    // console.log(data.data)
                })
            },
            getMsgByRedis(){
                let postData = {user_id:this.user_id}// 请求参数
                Api('UserMsgBySysAndRedis',postData,this).then(data => {
                    if(data.status==0){
                        var res = data.data.data
                        if(res){
                            let Base64 = require('js-base64').Base64
                            for(var i=0;i<res.length;i++){
                                res[i].msg = Base64.decode(res[i].msg)
                                this.msgList.push(res[i])
                            }
                            // this.msgList = res;
                        }

                    }
                    // console.log(data.data)
                })
            },
            sendMsg(){
                if(!this.msg || this.msg == ""){
                    this.$message({
                        message: 'Input empty',
                        type: 'error'
                    });
                    return;
                }
                this.msg_id = this.msg_id + 1;
                let postData = {
                    id:this.msg_id,
                    type:1,
                    uid:'system',
                    fromUserName:"system",
                    toUserName:this.username,
                    toid:this.user_id,
                    msg:this.msg
                }// 请求参数
                // TokenApi('NewUserMsg',postData,this).then(data => {
                //     if(data.status==0){
                //         this.msg = ''
                //         // this.msgList.push({sender_id:2,msg:postData.msg,type:postData.type})
                //         this.getMsg();
                //     }
                //     // console.log(data.data)
                // })
                this.$socket.emit("sendMsg",JSON.stringify(postData));
                this.msg = '';
            },
            sendImg(){
                this.dialogVisible=false;
                if(!this.img || this.img == ""){
                    this.$message({
                        message: 'Input empty',
                        type: 'error'
                    });
                    return;
                }
                this.msg_id = this.msg_id + 1;
                let postData = {
                    id:this.msg_id,
                    type:1,
                    uid:'system',
                    fromUserName:"system",
                    toUserName:this.username,
                    toid:this.user_id,
                    msg:this.img
                }// 请求参数
                // TokenApi('NewUserMsg',postData,this).then(data => {
                //     if(data.status==0){
                //         this.msg = ''
                //         // this.msgList.push({sender_id:2,msg:postData.msg,type:postData.type})
                //         this.getMsg();
                //     }
                //     // console.log(data.data)
                // })
                this.$socket.emit("sendMsg",JSON.stringify(postData));
                this.img = '';
            },

        },
        created() {
            this.user_id = this.$route.params.uid
            this.username = this.$route.params.username
            // this.getMsg();
            let that = this;
            document.onkeypress = function(e) {
                var keycode = document.all ? event.keyCode : e.which;
                if (keycode == 13) {
                    that.sendMsg();
                    return false;
                }
            };
        },
        mounted() {
            // this.timer = setInterval(this.getMsgByRedis, 3000);
            this.$socket.emit("onLine",'system');
        },
        beforeDestroy() {
            // clearInterval(this.timer);
        },
        updated(){
            // 聊天定位到底部
            let ele = document.getElementById('chatdiv');
            ele.scrollTop = ele.scrollHeight;
        },
        sockets: {
            //查看socket是否渲染成功
            connect() {
                console.log("链接成功");
            },
            online(res){
                // this.$socket.emit("getMsg",'system');
                this.$socket.emit("historyMsg",this.user_id);
            },
            disconnect(){
                console.log("断开链接");
            },//检测socket断开链接
            reconnect(){
                console.log("重新链接");
                this.$socket.emit("onLine",'system');
            },
            //客户端接收后台传输的socket事件
            message(data) {
                let res = JSON.parse(data);
                if(res.uid == this.user_id){
                    this.$socket.emit('readMsg',res.uid);
                  if(res.type == 2){
                    res.msg = this.$IMG_URL + res.msg
                  }
                    this.msgList.push(res);
                }
                console.log("getMsg:"+res);
            },
            historyMsg(data) {
                let res = JSON.parse(data);
              if(res.type == 2){
                res.msg = this.$IMG_URL + res.msg
              }
                this.msgList.push(res);
                console.log("historyMsg:"+res);
            },
            receive(data) {
                let res = JSON.parse(data);
                this.msg_id = res.id;
              if(res.type == 2){
                res.msg = this.$IMG_URL + res.msg
              }
                console.log("getMsg:"+res);
                this.msgList.push(res);
            },

        }
    }
</script>

<style scoped>
    .msgsender{
        font-size: 14px;margin: 0px
    }
    .msgbody{
        border-radius: 5px;padding: 10px;color: #fafafa;width: fit-content;margin-top: 5px;padding-bottom: 20px;
    }
</style>
