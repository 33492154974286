<template>
  <div class="mine">

    <!--  <wd-navbar :title="this.$LANG.me">-->
    <!--    <span slot="left" @click="drawer = true">-->
    <!--      <el-button type="primary" icon="el-icon-menu"></el-button>-->
    <!--    </span>-->
    <!--  </wd-navbar>-->
    <div class="head-card" :style="bgdiv">
      <el-row style="text-align: left">
        <img src="@/assets/usdt.png" style="width: 60px;margin-top: 20px;margin-left: 20px">
      </el-row>
      <el-row style="margin-top: 20px">
        <label style="font-size: 15px">
          {{this.$LANG.liudongjd}}
        </label>
      </el-row>
      <el-row style="margin-top: 5px;">
        <label style="font-weight: bold;">
          <span style="font-size:21px;">{{ this.userinfo.jd }}</span>
        </label>
      </el-row>

    </div>

    <div style="height: 80px;line-height: 80px;width: 100%;background: #3c3ad5;color: white;font-size: 15px">
      <div style="width: 45%;float: left;line-height: 15px">
        <p style="color: #acacf6;font-size: 10px;margin-bottom: 0px;margin-top: 25px">{{this.$LANG.nowsl}}</p>
        <p style="margin-top: 0px">{{ this.userinfo.sl }} (PH/s)</p>
      </div>
      <el-divider direction="vertical" ></el-divider>
      <div style="width: 45%;float: right;line-height: 15px">
        <p style="color: #acacf6;font-size: 10px;margin-bottom: 0px;margin-top: 25px">{{this.$LANG.wkzsy}}</p>
        <p style="margin-top: 0px">{{ this.userinfo.all_profit }} USDT</p>
      </div>
    </div>

    <el-row style="margin-top: 20px;padding-left: 10px">
      <label style="font-size: 17px;float: left;margin-top: 3px">{{this.$LANG.nowstate}}</label>
      <label v-if="userinfo.status==1" class="ykswk">{{this.$LANG.ykswk}}</label>
<!--      <label v-if="userinfo.status==0" class="ykswk">{{this.$LANG.ddsq}}</label>-->
    </el-row>
    <el-row style="" class="info-box">
      <div style="width: 50%;float: left;" >
        <div :style="boxbg1" style="margin-right: 5px;" class="box-p">
          <p style="margin-bottom: 0px;">{{this.$LANG.bizhi}}</p>
          <p style="color:rgb(30, 29, 112);font-size:15px;margin-top: 0px">{{ this.userinfo.usdt }} USDT</p>
        </div>

      </div>
      <div style="width: 50%;float: right" >
        <div :style="boxbg2" style="margin-left: 5px;" class="box-p">
          <p style="margin-bottom: 0px;">{{this.$LANG.zrsy}}</p>
          <p style="color:rgb(30, 29, 112);font-size:15px;margin-top: 0px">{{ this.userinfo.y_profit }} USDT</p>
        </div>
      </div>
    </el-row>


    <el-container>
      <el-main>
        <el-row style="margin-bottom: 10px">
          <label style="font-size: 17px;float: left">{{this.$LANG.wksy}}：</label>
        </el-row>
        <div style="background:#61608d;color: white; font-size: 16px;text-align: left;padding: 10px;line-height:15px;border-radius: 5px 5px 0px 0px">{{this.$LANG.time}}
          <span style="float: right;">USDT</span>
        </div>
        <div class="list-container">
          <div class="list" v-if="count_sy>0">

            <div class="list-item" v-for="(item, index) in syData" :key="index">
              <div style="height: 20px;border-top: 1px solid #61608d;padding: 10px 0px" class="content">
                <div style="width: 100%">
                  <div style="color: rgba(0,0,0,0.85); font-size: 16px;text-align: left;padding: 5px 10px">{{item.created_at}}
                    <span style="float: right;">{{ item.value }}</span></div>
                </div>
              </div>
            </div>
          </div>
          <el-empty :description="$LANG.emptydata" style="width: 30%;margin: auto;margin-top: 50px;color: #303133" v-if="count_sy<1"></el-empty>
        </div>

      </el-main>
    </el-container>

    <el-container style="margin-bottom: 50px">
      <el-main>
        <el-row style="margin-bottom: 10px">
          <label style="font-size: 17px;float: left">{{this.$LANG.tiqsy}}：</label>
        </el-row>
        <div style="background:#61608d;color: white; font-size: 16px;text-align: left;padding: 10px;line-height:15px;border-radius: 5px 5px 0px 0px">{{this.$LANG.address}}
          <span style="float: right;">USDT</span>
        </div>
        <div class="list-container">
          <div class="list" v-if="count_out>0">

            <div class="list-item"  v-for="(item, index) in outData" :key="index">
              <div style="height: 20px;border-top: 1px solid #61608d;padding: 10px 0px" class="content">
                <div style="width: 100%">
                  <div style="color: rgba(0,0,0,0.85); font-size: 16px;text-align: left;padding: 5px 10px">{{ item.in_address.substring(0,6)+"******"+item.in_address.substring(item.in_address.length-5,item.in_address.length) }}
                    <span style="float: right;">{{ item.number }}</span></div>
                </div>
              </div>
            </div>
          </div>
          <el-empty :description="$LANG.emptydata" style="width: 30%;margin: auto;margin-top: 50px;color: #303133" v-if="count_out<1"></el-empty>
        </div>

      </el-main>
    </el-container>

    <Footer></Footer>
    <a style="position: fixed;right: 20px;bottom:70px;width: 50px;height: 50px;background: #F5F5F5;z-index: 9999;border-radius: 5px;border: #1E56A0 1px solid" href="/#/chat" id="showchat">
      <img src="@/assets/chat.png"/>
    </a>
  </div>

</template>
<style lang="scss" scoped>
@import "@/style/variable.scss";
.el-divider--vertical{
  height: 40%;
}
.mine {
  min-height: 100vh;
  background-color: $bg-color;
}
  .el-aside {
    color: #333;
  }

  .el-card{
    margin-bottom: 20px;
  }
  .info-box{
    padding: 10px;
  }
.info-box p{
  text-align: left;
}
.info-box .box-p{
  height: 100%;
  padding: 10px;
  color: rgb(68, 68, 68);
  font-size: 13px;
}
  .cellimg{
    width: 40px;height: 40px;float: left
  }
  .cname{
    margin-left:5px;float: left;color: $text-color;line-height: 40px;
  }

  .cright{
    float: right;width: 150px;text-align: right
  }

  .money1{
    color: $text-color;line-height: 20px;font-weight: bold;width: 100%;display:block
  }
  .money2{
    color: #ccc;line-height: 20px;width: 100%;font-size: small;display:block
  }
  .line{
    display: block;height: 1px;width: 100%;margin: 10px 0;background-color: #333;position: relative;
  }
  .head-card {
    width: 100%;
    height: 180px;
    color: white;
    background-size:100% 100%;
    -moz-background-size:100% 100%;
    border:none;
  }
  .card-label {
    float: left;
    font-size:26px;
    font-weight: bold;
    color: #fff;
  }
  .list-container{
    border-left: 1px #61608d solid;
    border-right: 1px #61608d solid;
    border-bottom: 1px #61608d solid;
    height: 286px;
    overflow-y: scroll;
  }
  .list-container .list{
    background: #f3f3ff;
  }
.list-container .item{
    background: #f3f3ff;
  }
  .ykswk{
    float: left;
    margin-left: 10px;
    color: #000;
    background: #f6e724;
    border-radius: 10px;
    padding: 5px 10px;
    line-height: 20px;
    font-size: 15px;
  }

</style>
<style>
  .onlythis .wd-picker__field{
    padding: 0px;
  }
</style>

<script>
  import Footer from "@/components/Footer";
  import { Api } from '@/api/interface' // 引入接口
  import { TokenApi } from '@/api/interface' // 引入接口
  import { dateFormat } from '@/api/units' // 引入接口
  export default {
    data() {
      return {
        activeIndex:'1-1',
        activeTab: 'first',
        outData: [],
        count_out:0,
        syData: [],
        count_sy:0,
        userinfo:{cny:"US",rate:1.0,jd:235864,sl:0.00,usdt:0.00,all_profit:0.00,y_porfit:0.00},
        bgdiv: {
          backgroundImage:'url(' + require('@/assets/Personal.png') + ')',
          backgroundRepeat:'no-repeat',
          backgroundSize: 'fixed'
        },
        boxbg1: {
          backgroundImage:'url(' + require('@/assets/currency.png') + ')',
          backgroundRepeat:'no-repeat',
          backgroundSize: '100%'
        },
        boxbg2: {
          backgroundImage:'url(' + require('@/assets/Earnings.png') + ')',
          backgroundRepeat:'no-repeat',
          backgroundSize: '100%'
        },
        drawer: false,
      }
    },
    components: {
      Footer
    },
    methods:{

      getshouyi(){
        TokenApi('/SuanliLog', {type:8},this,'get').then(data => {
          if(data.status==0){
            this.count_sy = data.data.count;
            if(data.data.data.length){
              this.syData = this.syData.concat(data.data.data);
            }
          }else{
            this.$toast.error(data.msg);
          }
        })
      },
      getout(){
        Api('/UsdtOutLogAll', {},this,'get').then(data => {
          if(data.status==0){
            this.count_out = data.data.count;
            if(data.data.data.length){
              this.outData = this.syData.concat(data.data.data);
            }
          }else{
            this.$toast.error(data.msg);
          }
        })
      },
      getInfo(){
        let postData = {}// 请求参数
        TokenApi('/UserInfo',postData,this,'get').then(data => {
          if(data.status==0){
            this.userinfo = data.data;
          }else{
            if(data.msg == "token error" || data.data == "token error"){
              localStorage.setItem('accessToken','');
              this.$router.push({ path: '/index' });
            }
          }
          this.getshouyi();
          this.getout();
          console.log(data.data)
        })
      },
    },
    created() {
      //默认菜单
      localStorage.setItem("activeIndex","4");
      const token = localStorage.getItem("accessToken")
      if(token){
        this.getInfo();
      }

    }
  };
</script>
