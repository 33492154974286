<template>
  <div>

    <wd-navbar :title="article.title" >
      <span slot="left" @click="goback">
        <wd-icon name="arrow-left" />
      </span>
    </wd-navbar>

    <el-container>
      <el-main>
        <el-row>
          <p style="text-align: left">{{article.intro}}</p>

        </el-row>
        <img :src="article.cover" style="width: 100%;"/>
        <div class="list-container" id="addHere" style="text-align: left">
        </div>
      </el-main>
    </el-container>

  </div>
</template>
<style scoped>
  .content, .title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .content {
    justify-content: flex-start;
  }
  .title {
    justify-content: space-between;
  }
  .title-tip {
    color: rgba(0, 0, 0, 0.25);
    font-size: 12px;
  }
  input{
    background: #fafafa;color: black;
  }
</style>

<script>

  import { Api } from '@/api/interface' // 引入接口
  export default {
    data() {
      return {
        pair:{},
        aid:0,
        loading: false,
        count:1,
        article: {},
      }
    },
    methods:{
      goback(){
        this.$router.go(-1)
      },
      getData(){
        Api('/no_token/articleInfo/'+this.aid,{}).then(data => {
          this.loading = true
          if(data.code==0){
            if(data.data){
              this.article = data.data;
              document.getElementById("addHere").innerHTML = this.article.content;
            }
          }else{
            this.$message.error(data.msg);
          }
          this.loading = false;
        })
      },
    },
    created() {
      if(this.$route.query.aid){
        this.aid = this.$route.query.aid;
        this.getData()
      }
    },
  };
</script>
<style lang="scss" >
  @import "@/style/variable.scss";

  .mine-exchange {
    min-height: 100vh;
    background-color: $bg-color;
  }
  .header {
    background: $bg-color-qian;
  }
</style>
