import request from './http' // 使用实例
import qs from 'qs'

export function Test(params) {
  return request({
    url: '/v1/bpi/currentprice.json',
    method: 'get',
    params
  })
}

export function symbol24hr(params) {
  return request({
    url: 'https://api.binance.com/api/v3/ticker/24hr?symbol='+params.symbol,
    method: 'get'
  })
}

export function Api(url,params) {
  return request({
    url: url,
    method: 'get',
    params:params
  })
}

// export function TokenApi(url,params,that=null,method="post") {
//   const token = localStorage.getItem("accessToken")
//   if(!token){
//     that.$message.error('please login first');
//     that.$router.push({ path: '/login' });
//     return null;
//   }
//   // request.headers.set('token', "Bearer ")
//   var response = request({
//     url: url,
//     method: method,
//     // params:params,//get传
//     data:params, // 适应JAVA的参数接收方式，post
//     headers: {"token": token,"Content-Type": "application/json"},
//   })
//   response.then(data => {
//     if(data.msg == "token error" || data.data == "token error") {
//       console.log("Token error please login again");
//       that.$message.error(`Token error please login again`);
//       localStorage.setItem('accessToken','');
//       that.$router.push({ path: '/login' });
//       return null;
//     }
//   })
//   return response
// }

export function TokenApi(url,params,that=null,method="get") {
  const token = localStorage.getItem("accessToken")
  if(!token){
    that.$message.error(that.$LANG.loginfirst);
    that.$router.push({ path: '/index' });
    return null;
  }
  let obj = {}
  if(method == 'get'){
    obj = {
      url: url,
      method: method,
      params:params,//get传
      headers: {"token": token,"Content-Type": "application/json"},
    }
  }else{
    obj = {
      url: url,
      method: method,
      data:params, // 适应JAVA的参数接收方式，post
      headers: {"token": token,"Content-Type": "application/json"},
    }
  }
  var response = request(obj)
  response.then(data => {
    if(data.msg == "token error" || data.data == "token error") {
      console.log("Token error please login again");
      that.$message.error(`Token error please login again`);
      // localStorage.setItem('accessToken','');
      // that.$router.push({ path: '/index' });
      return response;
    }
  })
  return response
}



