<template>
  <div style="width:100%">
    <wd-navbar :title="this.$LANG.tiqsy" >
      <span slot="left" @click="goback">
        <wd-icon name="arrow-left" />
      </span>
    </wd-navbar>
    <el-row style="width: 100%;padding: 20px;" class="row-bg">

      <el-form  :model="addForm" status-icon :rules="rules" ref="addForm">
        <div class="form-label" style="width:100%;text-align: left">{{this.$LANG.bizhong}}</div>
        <el-form-item  prop="currency" style="width: 100%;margin-top: 5px ">
          <el-select v-model="addForm.currency" :placeholder="this.$LANG.select" style="width: 100%">
            <el-option value="USDT" label="USDT">USDT</el-option>
          </el-select>
        </el-form-item>

        <div class="form-label"  style="width:100%;text-align: left">{{this.$LANG.outaddress}}</div>
        <el-form-item prop="address" style="width: 100%;">
          <wd-input v-model="addForm.address" :placeholder="this.$LANG.pljieshouaddr"></wd-input>
        </el-form-item>
        <div class="form-label"  style="width:100%;text-align: left">{{this.$LANG.number}}</div>
        <el-form-item prop="number" style="width: 100%">
          <wd-input  v-model="addForm.number" :placeholder="this.$LANG.plzhuanchunum"></wd-input>
          <span style="float: left">{{this.$LANG.active_number}}： {{activeUsdt}} USDT</span>
        </el-form-item>

        <el-form-item style="margin-top: 60px">
          <wd-button type="primary" @click="submitForm('addForm')" style="width: 100%;border-radius: 5px">{{this.$LANG.submit}}</wd-button>
        </el-form-item>
      </el-form>
    </el-row>
  </div>
</template>

<script>
  import { TokenApi } from '@/api/interface' // 引入接口
  import { Api } from '@/api/interface' // 引入接口
  export default {
    name: "Exchange",
    data() {
      var validateinput = (rule, value, callback) => {
        if (value === '') {
          callback(new Error(this.$LANG.cannotempty));
        } else {
          callback();
        }
      };
      return {
        bgdiv: {
          backgroundImage:'url(' + require('@/assets/quickBuyBg.png') + ')',
          backgroundRepeat:'no-repeat',
        },
        activeUsdt:0,
        fee:0.05,
        min:0,
        max:1000,
        realGet:0.000000,
        activeName: 'first',
        tableData: [],
        addForm: {
          number:0,
          address: "",
          type:"TRC20",
          currency:"USDT",
          info:null,//后端框架问题，这里取个巧，用info放密码
        },
        rules: {
          number: [
            { validator: validateinput, trigger: 'blur' }
          ]
        },
      }
    },
    methods:{

      goback(){
        this.$router.go(-1)
      },
      submitForm(formName){
        this.$refs[formName].validate((valid) => {
          if (valid) {
            // if(this.addForm.number < this.min || this.addForm.number > this.max){
            //   this.$toast.error("min:"+this.min+" max:"+this.max);
            //   return;
            // }
            TokenApi('/UsdtOut',this.addForm,this,'get').then(data => {
              if(data.status==0){
                this.$message({
                  message: this.$LANG.success,
                  type: 'success'
                });
                this.addForm.number = 0;
                this.goback()
              }else{
                this.$message.error(data.data);
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      getUsdt(){
        TokenApi('/UserInfo',{},this,'get').then(data => {
          if(data.status==0){
            this.activeUsdt = data.data.profit;
            this.addForm.address = data.data.username;
          }
          console.log(data.data)
        })
      },
      getConfig(){
        let postData = {} // 请求参数
        Api('/config/2',postData).then(data => {
          if(data.code==0){
            this.fee = data.data.out_fee;
            this.min = data.data.out_min;
            this.max = data.data.out_max;
          }
        })
      },
    },
    created() {
      this.getUsdt();
      // this.getConfig();
    }

  }
</script>

