<template>
  <wd-tabbar v-model="tabbar" fixed border class="tabbar">
    <wd-tabbar-item icon="wd-icon-bags" @click="handleClick" to="/index">{{this.$LANG.index}}</wd-tabbar-item>
    <wd-tabbar-item icon="wd-icon-list"  @click="handleClick" to="/asset">{{this.$LANG.tongji}}</wd-tabbar-item>
    <wd-tabbar-item icon="wd-icon-chat"  @click="handleClick" to="/me">{{this.$LANG.me}}</wd-tabbar-item>
  </wd-tabbar>
</template>

<script>
  import { Api } from '@/api/interface' // 引入接口
  export default {
    name: "Footer",
    data(){
      return{
        tabbar:null
      }
    },
    methods:{
      getLogo(){
        let postData = {} // 请求参数
        Api('/no_token/config/1',postData).then(data => {
          if(data.code==0){
            this.title = data.data.site_name;
            console.log(data.data)
          }
        })
      },
      handleClick(key,path){
        console.log(key,path);
      }
    },
    created() {
      // this.getLogo()
    }
  }
</script>

<style lang="scss" scoped>
  .mycoin:before{
    background-image: url("http://u.thsi.cn/avatar/9423/115019423.gif");
  }
  .tabbar {
    ::v-deep {
      .wd-tabbar-item {
        color: #848e9c;
      }
    }
    .router-link-active {
      color: #4d80f0;
    }

  }
</style>
