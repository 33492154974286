<template>

  <div>

    <wd-navbar :title="this.$LANG.sljs" >
      <span slot="left" @click="goback">
        <wd-icon name="arrow-left" />
      </span>

    </wd-navbar>


    <el-container>

      <el-main>

        <el-table
            :data="tableData"
            border
            style="width: 100%">

          <el-table-column :label="this.$LANG.wallusdt">
            <template slot-scope="scope">
              {{scope.row.min_number}} ~ {{scope.row.max_number}}
            </template>
          </el-table-column>
          <el-table-column
              :label="this.$LANG.dayin"
              width="95px">
            <template slot-scope="scope">
              x {{scope.row.day_rate}}
            </template>
          </el-table-column>
          <el-table-column
              :label="this.$LANG.monthin"
              width="95px">
            <template slot-scope="scope">
              {{scope.row.month_rate}} (%)
            </template>
          </el-table-column>
        </el-table>

      </el-main>
    </el-container>

  </div>
</template>
<style>
  .content, .title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .content {
    justify-content: flex-start;
  }
  .title {
    justify-content: space-between;
  }
  .title-tip {
    color: rgba(0, 0, 0, 0.25);
    font-size: 12px;
  }
</style>

<script>

  import { Api } from '@/api/interface' // 引入接口
  export default {
    data() {
      return {
        tableData: [],
      }
    },
    components: {},
    methods:{
      goback(){
        this.$router.go(-1)
      },

      getData(){

        Api('/ProfitRate', {},this,'get').then(data => {
          if(data.status==0){
              this.tableData = data.data;
          }else{
            this.$message.error(data.msg);
          }
        })

      },


    },
    created() {
      //默认菜单
      //this.getData(this.listForm);
      this.getData()
    },
    mounted(){

    },
    destroyed(){

    }
  };
</script>
