//import request from "./http";

export const ALLLANG =[
  {
    joinnow:"Join Now",
    top1:"Committed to building the world's largest DeFi platform integrating DEX, IMO, and DAO",
    top2:"The project starts from 100USDT",
    top3:"No need to transfer currency / Steady income / Free exit",
    wkjj:"Introduction to Mining",
    jjcontent:"This project is about the blockchain protocol. It is like a decentralized central bank that establishes a money market through smart contracts. The money market is based on the supply and demand of assets and algorithmically derives the interest rate of the asset pool. USDT Providers of assets (participants, we call them “miners”) interact directly with the smart contract protocol without any actual collateral, automatically earning floating rates without negotiating terms such as terms and rates or collateral with peers or counterparties. It allows USDT holders to automatically explore the borrowing needs of the currency market and automatically match higher interest orders after agreeing to authorize the execution of the contract (becoming a miner). It is an AMM mechanism and automatic quantification program. Deposits are always in In your account, it is equivalent to your current deposit with the bank. The funds of the checking deposit are not withdrawn, but the bank actually uses it to borrow and earn interest. The agreement is the same. Your deposit is always in your In your own wallet, there is no need to withdraw cash, you can withdraw the interest earned to your wallet at any time. Become a miner, hand it over to the smart contract agreement, and automatically earn income!" ,
    youshi:"Our Advantage",
    aqfxin:"Be safe and secure",
    aqfxinc:"No need to transfer currency, USDT is stored in your own wallet, 0 risk",
    zytdui:"Professional Team ",
    zytduic:"Operates safely based on coinbase and the Binance team",
    dmkru:"Low threshold entry",
    dmkruc:"Storage starting from 100USDT, share the mining revenue of nodes",
    cjwti:"FAQ",
    sjjgou:"Audit Agency",
    hzhban:"Partner",
    smsjd:"∆ What is node revenue?",
    smssyl:"∆What is the storage yield?",
    cnalilai:"∆ Where does mining revenue come from?",
    smshhjisuan:"∆ When does start calculating revenue?",
    srguize:"∆ Income calculation rules?",
    smsjdc:"USDT stores its own wallet with 0 risk, and the data management and operation model of the new blockchain contributes to DEFI's decentralized ecology. After each user proves the encrypted assets they hold, all of this will happen Automatically lock the node execution revenue through smart contracts. Now you can participate in any wallet and adopt a new generation of node sharing method. The total revenue obtained through liquidity node sharing will be divided into each user's centralized wallet through USDT tokens",
    smssylc:"The daily rate of return is about 0.5% - 4.8%; the larger the USDT storage amount, the higher the rate of return",
    cnalilaic:"All storage rewards given to users are automatically matched by the smart contracts of USDT nodes. Asset suppliers interact directly with the protocol to earn floating rates without negotiating terms, rates or collateral with peers or counterparties. USDT held The more, the more reward.",
    smshhjisuanc:"Once you log in and authorize your participation in the sharing node, you can start calculating your earnings on the same day. We calculate your token value multiple times a day (from time to time). This is equivalent to your current bank deposit. If the system checks at any time on the same day The number of USDT nodes and no balance, you will have no earnings.",
    srguizec:"• Total revenue: Calculated based on the value of USDT tokens in the user's wallet" +
        "• Earning time: Settled every 24 hours" +
        "• Revenue calculation: Calculate your computing power according to the ratio of the USDT amount you deposit, And get the corresponding income according to the computing power" +
        "For example: the wallet holds 10,000 USDT tokens, the estimated daily rate of return is about 1%, so your daily income is about 100 USDT",
    liudongjd:"Total Liquidity Node",
    nowsl:"Current hashrate",
    wkzsy:"Total mining revenue",
    nowstate:"Current status:",
    ykswk:"Mining started",
    bizhi:"currency value",
    zrsy:"Yesterday's earnings",
    wksy:"Mining Earnings",
    tiqsy:"Withdraw profit",
    time:"time",
    address:"address",
    hqpz:"Get Credentials",
    index:"Index ",
    tongji:"Pool",
    me:"Mine ",
    ktqje:"Amount available",
    tiqumingx:"Extract details",
    sljs:"Mining power",
    dayin:"Daily Interest",
    monthin:"Monthly rate of return",
    wallusdt:"Wallet’s Usdt",


    invitation:"Invite Friends",
    login:"Login",
    register:"Register",
    inputicode:"Invite Code ",
    logout:"Exit ",
    logac:"Account ",
    from:"From ",
    to:"to ",
    usdtout:"转出资金 ",
    bizhong:"Currency ",
    select:"Please Select ",
    zhuanzhangtype:"Transfer network ",
    outaddress:"To address ",
    number:"Number ",
    amount:"Amount ",
    pleaseamount:"Please input Amount ",
    price:"Price ",
    active_number:"Available Balance ",
    complete_number:"成交金额 ",
    real_get:"实际到账 ",
    fee:"Fee ",
    submit:"Submit ",
    all:"All ",
    onlyusdt:"此地址只可接收USDT ",
    tips:"Tips ",
    zhangdangliushui:"资产明细 ",
    zhuangchujilu:"转出记录 ",
    withdrawlog:"提现记录 ",
    tuijian:"推荐设置 ",
    usdtaddress:"USDT地址 ",
    allzc:"总资产价值 ",
    canusezc:"可用资产 ",
    marketzc:"市值资产 ",
    withdraw:"提现 ",
    search:"Search ",
    bgday:"Begin day ",
    endday:"End day ",
    checkday:"Please a Day ",
    type:"Type ",
    billtype:"账单类型 ",
    option:"Options ",
    beizu:"Note ",
    createtime:"创建时间 ",
    check:"查询 ",
    change:"修改 ",
    tuijianlink:"我的推荐链接 ",
    leijishouyi:"累计收益 ",
    wodetuijian:"我的推荐 ",
    leijituijian:"累计推荐 ",
    addusdtaddress:"添加提现地址 ",
    add:"添加 ",
    ccel:"取消 ",
    copy:"复制 ",
    man:"人次 ",
    status:"状态 ",
    plzhuanchunum:"请输入转出数量 ",
    pljieshouaddr:"Please enter the receiving wallet address ",
    pltxnum:"请输入提现数量 ",
    clickcopy:"点击复制 ",
    kefu:"Customer Service",
    sendmessage:"Send",
    inputsearchword:"请输入关键词搜索 ",
    waiting:"Waiting ",
    finished:"Finished ",
    failed:"Failed ",
    copylink:"复制推荐链接 ",
    copycode:"复制推荐码 ",
    invitecode:"Register Code",
    notice:"要闻",
    save:"保存",
    slang:"Switch language",
    article:"最新资讯",
    noticemsg:"通知消息",
    other:"其他",
    tjjl:"推荐奖励",
    sysadd:"系统添加",
    syssub:"系统扣减",
    success:"操作成功",
    emptydata:"it's empty",
    regok:"注册成功",
  },
  {
    joinnow:"立即加入",
    top1:"致力打造全球最大的集DEX、IMO、DAO为一体的DeFi平台",
    top2:"项目最低100USDT起",
    top3:"无需转币 / 稳健收益 / 自由退出",
    wkjj:"挖矿简介",
    jjcontent:"这个项目是关于区块链协议的。它就像一个去中心化的中央银行，通过智能合约建立货币市场。货币市场以资产的供求为基础，通过算法推导出资产池的利率。 USDT 资产的提供者（参与者，我们称之为“矿工”）直接与智能合约协议交互，无需任何实际抵押，自动赚取浮动利率，无需与同行或交易对手协商期限和利率或抵押品等条款。它允许USDT持有者在同意授权执行该合约（成为“矿工”）后，自动探索币市的借贷需求并自动匹配更高利息的订单。它是一种 AMM 机制和自动量化程序。存款始终在您的账户中，相当于您当前在银行的存款。活期存款的资金并没有被提取出来，但银行实际上是用它来借贷和赚取利息的。协议是一样的。您的存款始终在您自己的钱包里，无需提现，您可以随时将赚取的利息提取到您的钱包中。成为“矿工”，交给智能合约协议，自动赚取收益！\n" ,
    youshi:"我们的优势",
    aqfxin:"安全放心",
    aqfxinc:"无需转币，USDT存放自己钱包 ，“0”风险",
    zytdui:"专业团队 ",
    zytduic:"基于coinbase和币安团队安全运作",
    dmkru:"低门槛入",
    dmkruc:"100USDT起存放，共享节点挖矿收益",
    cjwti:"常见问题",
    sjjgou:"审计机构",
    hzhban:"合作伙伴",
    smsjd:"∆ 什么是节点收益?",
    smssyl:"∆ 储存收益率是多少?",
    cnalilai:"∆ 挖矿收益从哪里来?",
    smshhjisuan:"∆ 什么时候开始计算收入?",
    srguize:"∆ 收入计算规则?",
    smsjdc:"USDT 以“0”风险存储自己的钱包，新区块链的数据管理和运营模式为 DEFI 的去中心化生态做出贡献。 在每个用户证明自己持有的加密资产后，这一切都会通过智能合约自动锁定节点执行收益。 现在你可以参与任何钱包，采用新一代节点共享方式。 通过流动性节点共享获得的总收益将通过USDT代币分成每个用户的中心化钱包",
    smssylc:"每日收益率约为0.5%——4.8%；USDT存储额度越大收益率越高",
    cnalilaic:"所有给予用户的储存奖励均由USDT节点的智能合约自动匹配。 资产供应商直接与协议互动以赚取浮动利率，无需与同行或交易对手协商条款、利率或抵押品。 持有的USDT越多，奖励越多。",
    smshhjisuanc:"登录并授权参与共享节点后，当天即可开始计算收益。 我们每天（不时）多次计算您的代币价值。 这相当于您当前在银行的存款。 如果系统在同一天任何时间检查USDT节点的数量并且没有余额，您将没有收益。",
    srguizec:"• 总收益：以用户钱包中USDT代币价值计算为核心\n" +
        "• 赚取时间：每24小时结算一次 \n" +
        "• 收益计算：根据您存入USDT金额的比例计算您的算力，并根据算力获得相应的收益\n" +
        "例如：钱包持有 10,000 USDT 代币，预计每日收益率约为 1%，因此您的每日收入约为 100 USDT",
    liudongjd:"总流动性节点",
    nowsl:"当前算力值",
    wkzsy:"挖矿总收益",
    nowstate:"当前状态:",
    ykswk:"已开始挖矿",
    bizhi:"币值",
    zrsy:"昨日收益",
    wksy:"挖矿收益",
    tiqsy:"提取收益",
    index:"Index ",
    tongji:"Pool",
    me:"Mine ",
    time:"时间",
    address:"地址",
    hqpz:"获取凭证",
    ktqje:"可提取金额",
    tiqumingx:"提取明细",
    sljs:"算力介绍",
    dayin:"日收益",
    monthin:"月收益",
    wallusdt:"钱包余额",
    emptydata:"没有数据",
    waiting:"处理中 ",
    finished:"已完成 ",
    failed:"已失败 ",
    register:"注册 ",
    inputicode:"请输入邀请码 ",
    usdtout:"转出资金 ",
    bizhong:"币种 ",
    select:"请选择 ",
    zhuanzhangtype:"转账网络 ",
    outaddress:"转出地址 ",
    search:"搜索 ",
    bgday:"开始日期 ",
    endday:"结束日期 ",
    checkday:"请选择日期 ",
    kefu:"联系客服 ",
    invitecode:"注册码",
    slang:"语言选择",


    invitation:"邀请好友 ",
    login:"登录 ",
    logout:"退出 ",
    logac:"账号 ",
    from:"从 ",
    to:"转到 ",
    safepsw:"安全密码 ",
    inputsafepsw:"请输入安全密码 ",
    number:"数量 ",
    amount:"金额 ",
    pleaseamount:"请输入金额 ",
    price:"价格 ",
    active_number:"可用余额 ",
    complete_number:"成交金额 ",
    real_get:"实际到账 ",
    fee:"手续费 ",
    submit:"提交 ",
    all:"全部 ",
    onlyusdt:"此地址只可接收USDT ",
    tips:"温馨提示 ",
    zhangdangliushui:"资产明细 ",
    zhuangchujilu:"转出记录 ",
    withdrawlog:"提现记录 ",
    tuijian:"推荐设置 ",
    usdtaddress:"USDT地址 ",
    allzc:"总资产价值 ",
    canusezc:"可用资产 ",
    marketzc:"市值资产 ",
    withdraw:"提现 ",
    type:"类型 ",
    billtype:"账单类型 ",
    option:"操作 ",
    beizu:"备注 ",
    createtime:"创建时间 ",
    check:"查询 ",
    change:"修改 ",
    tuijianlink:"我的推荐链接 ",
    leijishouyi:"累计收益 ",
    wodetuijian:"我的推荐 ",
    leijituijian:"累计推荐 ",
    addusdtaddress:"添加提现地址 ",
    add:"添加 ",
    ccel:"取消 ",
    copy:"复制 ",
    man:"人次 ",
    status:"状态 ",
    plzhuanchunum:"请输入转出数量 ",
    pljieshouaddr:"请输入接收地址 ",
    pltxnum:"请输入提现数量 ",
    clickcopy:"点击复制 ",
    sendmessage:"发送 ",
    inputsearchword:"请输入关键词搜索 ",
    copylink:"复制推荐链接 ",
    copycode:"复制推荐码 ",
    notice:"要闻",
    save:"保存",
    article:"最新资讯",
    noticemsg:"通知消息",
    other:"其他",
    tjjl:"推荐奖励",
    sysadd:"系统添加",
    syssub:"系统扣减",
    success:"操作成功",
    regok:"注册成功",

  },
  {
    joinnow:"立即加入 ",
    top1:"致力打造全球最大的集DEX、IMO、DAO為一體的DeFi平臺 ",
    top2:"項目最低100USDT起 ",
    top3:"無需轉幣/穩健收益/自由退出 ",
    wkjj:"挖礦簡介 ",
    jjcontent:"這個項目是關於區塊鏈協定的。 它就像一個去中心化的中央銀行，通過智慧合約建立貨幣市場。 貨幣市場以資產的供求為基礎，通過算灋推導出資產池的利率。 USDT資產的提供者（參與者，我們稱之為“礦工”）直接與智慧合約協定互動，無需任何實際抵押，自動賺取浮動利率，無需與同行或交易對手協商期限和利率或抵押品等條款。 它允許USDT持有者在同意授權執行該合約（成為“礦工”）後，自動探索幣市的借貸需求並自動匹配更高利息的訂單。 它是一種AMM機制和自動量化程式。 存款始終在您的帳戶中，相當於您當前在銀行的存款。 活期存款的資金並沒有被選取出來，但銀行實際上是用它來借貸和賺取利息的。 協定是一樣的。 您的存款始終在您自己的錢包裏，無需提現，您可以隨時將賺取的利息選取到您的錢包中。 成為“礦工”，交給智慧合約協定，自動賺取收益！ ",
    youshi:" ",
    aqfxin:"我們的優勢 ",
    aqfxinc:"安全放心 ",
    zytdui:"無需轉幣，USDT存放自己錢包，“0”風險 ",
    zytduic:"專業團隊 ",
    dmkru:"基於coinbase和幣安團隊安全運作 ",
    dmkruc:"低門檻入 ",
    cjwti:"100USDT起存放，共亯節點挖礦收益 ",
    sjjgou:"常見問題 ",
    hzhban:"稽核機构 ",
    smsjd:"合作夥伴 ",
    smssyl:"∆什麼是節點收益？ ",
    cnalilai:"∆儲存收益率是多少？ ",
    smshhjisuan:"∆挖礦收益從哪裡來？ ",
    srguize:"∆什麼時候開始計算收入？ ",
    smsjdc:"∆收入計算規則？ ",
    smssylc:"USDT以“0”風險存儲自己的錢包，新區塊鏈的資料管理和運營模式為DEFI的去中心化生態做出貢獻。 在每個用戶證明自己持有的加密資產後，這一切都會通過智慧合約自動鎖定節點執行收益。 現在你可以參與任何錢包，採用新一代節點共亯管道。 通過流動性節點共亯獲得的總收益將通過USDT代幣分成每個用戶的中心化錢包 ",
    cnalilaic:"每日收益率約為0.5%——4.8%； USDT存儲額度越大收益率越高 ",
    smshhjisuanc:"所有給予用戶的儲存獎勵均由USDT節點的智慧合約自動匹配。 資產供應商直接與協定互動以賺取浮動利率，無需與同行或交易對手協商條款、利率或抵押品。 持有的USDT越多，獎勵越多。 ",
    liudongjd:"登入並授權參與共亯節點後，當天即可開始計算收益。 我們每天（不時）多次計算您的代幣價值。 這相當於您當前在銀行的存款。 如果系統在同一天任何時間檢查USDT節點的數量並且沒有餘額，您將沒有收益。 ",
    srguizec:"• 總收益：以用戶錢包中USDT代幣價值計算為核心" +
        "• 賺取時間：每24小時結算一次 " +
        "• 收益計算：根據您存入USDT金額的比例計算您的算力，並根據算力獲得相應的收益" +
        "例如：錢包持有 10,000 USDT 代幣，預計每日收益率約為 1%，因此您的每日收入約為 100 USDT",
    nowsl:"總流動性節點 ",
    wkzsy:"當前算力值 ",
    nowstate:"挖礦總收益 ",
    ykswk:"當前狀態 ",
    bizhi:"已開始挖礦 ",
    zrsy:"幣值 ",
    wksy:"昨日收益 ",
    tiqsy:"挖礦收益 ",
    index:"選取收益 ",
    tongji:"Index ",
    me:"Pool ",
    time:"Mine ",
    address:"時間 ",
    hqpz:"地址 ",
    ktqje:"獲取憑證 ",
    tiqumingx:"可選取金額 ",
    sljs:"選取明細 ",
    dayin:"算力介紹 ",
    monthin:"日收益 ",
    wallusdt:"月收益 ",
    emptydata:"錢包餘額 ",
    waiting:"沒有數據 ",
    finished:"處理中 ",
    failed:"已完成 ",
    register:"已失敗 ",
    inputicode:"注册 ",
    usdtout:"請輸入邀請碼 ",
    bizhong:"轉出資金 ",
    select:"幣種 ",
    zhuanzhangtype:"請選擇 ",
    outaddress:"轉帳網絡 ",
    search:"轉出地址 ",
    bgday:"蒐索 ",
    endday:"開始日期 ",
    checkday:"結束日期 ",
    kefu:"請選擇日期 ",
    invitecode:"聯系客服 ",
    slang:"註冊碼 ",
    invitation:"語言選擇 ",
    login:"邀請好友 ",
    logout:"登入 ",
    logac:"退出 ",
    from:"帳號 ",
    to:"從 ",
    safepsw:"轉到 ",
    inputsafepsw:"安全密碼 ",
    number:"請輸入安全密碼 ",
    amount:"數量 ",
    pleaseamount:"金額 ",
    price:"請輸入金額 ",
    active_number:"價格 ",
    complete_number:"可用餘額 ",
    real_get:"成交金額 ",
    fee:"實際到賬 ",
    submit:"手續費 ",
    all:"提交 ",
    onlyusdt:"全部 ",
    tips:"此地址只可接收USDT ",
    zhangdangliushui:"溫馨提示 ",
    zhuangchujilu:"資產明細 ",
    withdrawlog:"轉出記錄 ",
    tuijian:"提現記錄 ",
    usdtaddress:"推薦設定 ",
    allzc:"USDT地址 ",
    canusezc:"總資產價值 ",
    marketzc:"可用資產 ",
    withdraw:"市值資產 ",
    type:"提現 ",
    billtype:"類型 ",
    option:"帳單類型 ",
    beizu:"操作 ",
    createtime:"備註 ",
    check:"創建時間 ",
    change:"査詢 ",
    tuijianlink:"修改 ",
    leijishouyi:"我的推薦連結 ",
    wodetuijian:"累計收益 ",
    leijituijian:"我的推薦 ",
    addusdtaddress:"累計推薦 ",
    add:"添加提現地址 ",
    ccel:"添加 ",
    copy:"取消 ",
    man:"複製 ",
    status:"人次 ",
    plzhuanchunum:"狀態 ",
    pljieshouaddr:"請輸入轉出數量 ",
    pltxnum:"請輸入接收地址 ",
    clickcopy:"請輸入提現數量 ",
    sendmessage:"點擊複製 ",
    inputsearchword:"發送 ",
    copylink:"請輸入關鍵字搜索 ",
    copycode:"複製推薦連結 ",
    notice:"複製推薦碼 ",
    save:"要聞 ",
    article:"保存 ",
    noticemsg:"最新資訊 ",
    other:"通知消息 ",
    tjjl:"其他 ",
    sysadd:"推薦獎勵 ",
    syssub:"系統添加 ",
    success:"系統扣减 ",
    regok:"操作成功 ",
  },
  {
    joinnow:"즉시 가입 ",
    top1:"세계 최대 규모의 DEX, IMO, DAO 통합 DeFi 플랫폼 구축 ",
    top2:"최소 100USDT부터 ",
    top3:"코인 전환/안정적 수익/자유 퇴출 필요 없음 ",
    wkjj:"채광 안내 ",
    jjcontent:"이 프로젝트는 블록체인 프로토콜에 관한 것이다.그것은 마치 탈중심화된 중앙은행처럼 스마트 계약을 통해 화폐시장을 구축한다.화폐 시장은 자산의 공급과 수요를 바탕으로 알고리즘을 통해 자산 탱크의 이율을 유도한다.USDT 자산의 제공자(참여자, 우리는'광부'라고 부른다)는 스마트 계약 협의와 직접 상호작용을 하며 어떠한 실제 담보도 필요 없고 자동적으로 변동이율을 벌며 동업자나 거래 상대방과 기한과 이율이나 담보물 등 조항을 협상할 필요가 없다.이것은 USDT 소유자가 이 계약 (광부가 됨) 을 집행할 수 있는 권한을 부여한 후 화폐시장의 대출 수요를 자동으로 탐색하고 더 높은 이자의 주문을 자동으로 일치하도록 허용한다.AMM 메커니즘 및 자동 계량화 프로그램입니다.예금은 시종 당신의 계좌에 있으며, 현재 은행에 있는 예금과 맞먹는다.당좌예금의 자금은 인출되지 않았지만, 은행은 사실상 그것으로 대출을 하고 이자를 벌었다.협의는 같다.당신의 예금은 시종 당신의 지갑 안에 있습니다. 현금을 인출할 필요가 없습니다. 당신은 수시로 벌어들인 이자를 당신의 지갑에서 인출할 수 있습니다.'광부'가 되어 스마트 계약 협의에 맡기면 자동으로 수익을 얻습니다!",
    youshi:" ",
    aqfxin:"우리의 우세 ",
    aqfxinc:"안전하고 안심하다 ",
    zytdui:"코인 이체 없이 USDT에 지갑 보관, 0 위험 ",
    zytduic:"전문 팀 ",
    dmkru:"코인베이스와 코인보안팀 기반 안전 운영 ",
    dmkruc:"낮은 문턱으로 들어가다 ",
    cjwti:"100USDT 보관, 노드 채굴 수익 공유 ",
    sjjgou:"FAQ ",
    hzhban:"감사 기구 ",
    smsjd:"파트너 ",
    smssyl:"∆노드 수익은 무엇입니까? ",
    cnalilai:"∆저장 수익률은 얼마입니까? ",
    smshhjisuan:"∆채광 수익은 어디에서 옵니까? ",
    srguize:"∆수입은 언제부터 계산합니까? ",
    smsjdc:"∆수입 계산 규칙? ",
    smssylc:"USDT는'0'위험으로 자신의 지갑을 저장하고 새로운 블록체인의 데이터 관리와 운영 모델은 DEFI의 탈중심화 생태에 기여한다.모든 사용자가 자신이 보유한 암호화 자산을 증명한 후에 이 모든 것은 스마트 계약을 통해 노드를 자동으로 잠그고 수익을 집행한다.이제 당신은 어떤 지갑에도 참여하고 차세대 노드 공유 방식을 채택할 수 있습니다.유동성 노드 공유를 통해 얻는 총 수익은 USDT 토큰을 통해 각 사용자의 중심화 지갑으로 나눌 것이다 ",
    cnalilaic:"일일 수익률은 약 0.5%-4.8%이다.USDT 스토리지 용량이 클수록 높은 수익률 ",
    smshhjisuanc:"사용자에게 주는 모든 저장 보상은 USDT 노드의 스마트 계약에 의해 자동으로 일치합니다.자산 공급업체는 협의와 직접 상호작용하여 변동 이율을 벌기 때문에 동업자나 거래 상대방과 조항, 이율 또는 담보물을 협상할 필요가 없다.보유한 USDT가 많을수록 보상이 많습니다. ",
    liudongjd:"로그인하여 공유 노드에 참여할 수 있는 권한을 부여하면 당일 수익 계산을 시작할 수 있습니다.우리는 매일 여러 차례 당신의 화폐 가치를 계산합니다.이것은 당신이 현재 은행에 있는 예금과 맞먹는다.시스템이 같은 날 언제든지 USDT 노드의 수를 검사하고 잔액이 없으면 수익이 없습니다. ",
    srguizec:"• 총 수익: 사용자의 지갑에 있는 USDT 토큰의 가치를 기준으로 계산" +
        "• 수익 시간: 24시간마다 정산" +
        "• 수익 계산: USDT 입금액의 비율에 따라 컴퓨팅 파워를 계산하고 컴퓨팅 파워를 계산합니다. 계산 능력에 따라 해당 수입을 얻습니다" +
        "예: 지갑에 10,000 USDT 토큰이 있고 예상 일일 수익률이 약 1%이므로 일일 수입은 약 100 USDT입니다",
    nowsl:"총 유동성 노드 ",
    wkzsy:"현재 산력치 ",
    nowstate:"채광 총수익 ",
    ykswk:"현재 상태 ",
    bizhi:"이미 채굴 시작 ",
    zrsy:"화폐가치 ",
    wksy:"어제 수익 ",
    tiqsy:"채광 수익 ",
    index:"수익 추출 ",
    tongji:"Index ",
    me:"Pool ",
    time:"Mine ",
    address:"시간 ",
    hqpz:"주소 ",
    ktqje:"자격 증명 얻기 ",
    tiqumingx:"인출 가능 금액 ",
    sljs:"인출 내역 ",
    dayin:"산력 소개 ",
    monthin:"일일 수익 ",
    wallusdt:"월 수익 ",
    emptydata:"지갑 잔액 ",
    waiting:"데이터 없음 ",
    finished:"처리 중 ",
    failed:"완료됨 ",
    register:"실패 ",
    inputicode:"등록 ",
    usdtout:"초대장을 입력하십시오. ",
    bizhong:"자금을 빼내다 ",
    select:"화폐의 종류 ",
    zhuanzhangtype:"선택하십시오. ",
    outaddress:"이체망 ",
    search:"주소 내보내기 ",
    bgday:"검색 ",
    endday:"시작 날짜 ",
    checkday:"종료 날짜 ",
    kefu:"날짜를 선택하십시오. ",
    invitecode:"고객지원 ",
    slang:"등록 번호 ",
    invitation:"언어 선택 ",
    login:"친구를 초대하다 ",
    logout:"로그인 ",
    logac:"물러나다 ",
    from:"계정 ",
    to:"... 에서 ",
    safepsw:"이동 ",
    inputsafepsw:"보안 암호 ",
    number:"보안 암호를 입력하십시오. ",
    amount:"수량 ",
    pleaseamount:"금액 ",
    price:"금액을 입력하십시오. ",
    active_number:"가격 ",
    complete_number:"사용 가능한 잔액 ",
    real_get:"거래 금액 ",
    fee:"실제 입금 ",
    submit:"수수료 ",
    all:"제출 ",
    onlyusdt:"모조리 ",
    tips:"이 주소는 USDT만 받을 수 있습니다. ",
    zhangdangliushui:"따스한 힌트 ",
    zhuangchujilu:"자산 내역 ",
    withdrawlog:"레코드 내보내기 ",
    tuijian:"현금 인출 기록 ",
    usdtaddress:"권장 설정 ",
    allzc:"USDT 주소 ",
    canusezc:"총자산가치 ",
    marketzc:"가용 자산 ",
    withdraw:"시가자산 ",
    type:"현금 인출 ",
    billtype:"유형 ",
    option:"계산서 유형 ",
    beizu:"조작하다 ",
    createtime:"비고 ",
    check:"작성 시간 ",
    change:"조회 ",
    tuijianlink:"수정하다 ",
    leijishouyi:"내 추천 링크 ",
    wodetuijian:"누적 수익 ",
    leijituijian:"나의 추천 ",
    addusdtaddress:"누적 추천 ",
    add:"현금 인출 주소 추가 ",
    ccel:"덧붙이다 ",
    copy:"취소 ",
    man:"복사 ",
    status:"인원수 ",
    plzhuanchunum:"상태 ",
    pljieshouaddr:"전송 수량을 입력하십시오. ",
    pltxnum:"수신 주소를 입력하십시오. ",
    clickcopy:"현금 인출 수량을 입력하십시오. ",
    sendmessage:"복사 클릭 ",
    inputsearchword:"보내다 ",
    copylink:"키워드 검색 입력 ",
    copycode:"추천 링크 복사 ",
    notice:"추천 코드 복사 ",
    save:"중요한 소식 ",
    article:"보존 ",
    noticemsg:"최신 정보 ",
    other:"알림 메시지 ",
    tjjl:"기타 ",
    sysadd:"추천 보상 ",
    syssub:"시스템 추가 ",
    success:"시스템 감액 ",
    regok:"작업 성공 ",
  },
  {
    joinnow:"今すぐ参加",
    top1:"DEX、IMO、DAOを統合した世界最大のDeFiプラットフォームの構築に取り組んでいます",
    top2:"最小プロジェクトは100USDTから始まります",
    top3:"通貨を変更する必要はありません/安定した収入/無料の出口",
    wkjj:"鉱業入門",
    jjcontent:"このプロジェクトは、スマートコントラクトを通じてマネーマーケットを構築する分散型銀行のようなものです。 資産の需要と供給に基づいて、資産プールの金利はアルゴリズムによって導き出されます。 USDT資産のプロバイダー（私たちはそれらを「マイナー」と呼びます）は、担保なしでスマートコントラクトプロトコルと直接対話し、変動金利を自動的に獲得します。 これにより、USDT保有者は、通貨市場の借入ニーズを自動的に調査し、契約の実行を承認することに同意した後、より高い金利の注文を自動的に照合することができます。 これは、AMMメカニズムおよび自動量子化装置です。 預金は常にあなたの口座にあり、あなたの現在の銀行預金と同等です。 小切手預金からのお金は引き出されませんが、銀行は実際にそれを借りて利子を稼ぐために使用します。 プロトコルは同じです。 あなたの預金は常にあなた自身の財布にあり、引き出す必要はありません、あなたはいつでもあなたの財布への獲得した利息を引き出すことができます。 「マイナー」になり、スマートコントラクト契約に引き渡して、自動的に収入を稼ぎましょう！" ,
    youshi:"私たちの利点",
    aqfxin:"安全で安心",
    aqfxinc:"通貨を送金する必要はありません、USDTはあなた自身の財布に保管されます、「0リスク」",
    zytdui:"プロチーム ",
    zytduic:"コインベースとBinanceチームに基づく安全な運用",
    dmkru:"低エントリー",
    dmkruc:"100USDTから入金し、ノードのマイニング収益を共有します",
    cjwti:"一般的な問題",
    sjjgou:"監査人",
    hzhban:"相棒",
    smsjd:"∆ ノード収益とは何ですか？",
    smssyl:"∆ 保管時の歩留まりはどれくらいですか?",
    cnalilai:"∆ 鉱業収入はどこから来るのですか？",
    smshhjisuan:"∆ 収入はいつ計算されますか？",
    srguize:"∆ 収入計算ルール？",
    smsjdc:"USDTは、リスクが「0」の独自のウォレットを保管しており、新しいブロックチェーンのデータ管理と運用モデルは、DEFIの分散型エコロジーに貢献しています。 各ユーザーが保持している暗号化された資産を証明した後、これらすべてがスマートコントラクトを通じてノード実行収益を自動的にロックします。 これで、新世代のノード共有を使用して任意のウォレットに参加できます。 流動性ノードの共有を通じて得られた総収益は、USDTトークンを通じて各ユーザーの集中型ウォレットに分割されます",
    smssylc:"1日の収益率は約0.5％〜4.8％です。USDTの保管量が多いほど、収益率は高くなります。",
    cnalilaic:"ユーザーに与えられるすべてのストレージ報酬は、USDTノードのスマートコントラクトによって自動的に照合されます。 資産供給者は、プロトコルと直接対話して、条件、レート、または担保をピアやカウンターパーティと交渉することなく、変動レートを獲得します。 USDTが多ければ多いほど、より多くの報酬が得られます。",
    smshhjisuanc:"ユーザーに与えられるすべてのストレージ報酬は、USDTノードのスマートコントラクトによって自動的に照合されます。 資産供給者は、プロトコルと直接対話して、条件、レート、または担保をピアやカウンターパーティと交渉することなく、変動レートを獲得します。 USDTが多ければ多いほど、より多くの報酬が得られます。",
    srguizec:"• 総収益：ユーザーのウォレット内のUSDTトークンの値に基づいて計算されます n•収益時間：24時間ごとに決済されます n•収益の計算：USDTデポジット額の割合に基づいて計算能力を計算し、計算能力を計算します計算能力に応じて対応する収入を得る例：ウォレットには10​​,000 USDTトークンがあり、推定1日あたりの収益率は約1％なので、1日あたりの収入は約100USDTです。",
    liudongjd:"総流動性ノード",
    nowsl:"現在のハッシュレート",
    wkzsy:"総鉱業収入",
    nowstate:"現在の状態:",
    ykswk:"マイニングが始まりました",
    bizhi:"通貨価値",
    zrsy:"昨日の収益",
    wksy:"鉱業収入",
    tiqsy:"引き出し収入",
    index:"Index ",
    tongji:"Pool",
    me:"Mine ",
    time:"時間",
    address:"住所",
    ktqje:"引き出し可能な金額",
    tiqumingx:"詳細を抽出する",
    sljs:"計算能力の紹介",
    wallusdt:"引き出し可能な金額",
    emptydata:" データなし",
    hqpz:"買掛伝票の取得 ",
    dayin:"日次収益 ",
    monthin:"月間収益 ",
    waiting:"処理中 ",
    finished:"完了 ",
    failed:"失敗しました ",
    register:"登録 ",
    inputicode:"招待コードを入力してください ",
    usdtout:"資金を振り替える ",
    bizhong:"通貨＃ツウカ＃ ",
    select:"選択してください ",
    zhuanzhangtype:"振替ネットワーク ",
    outaddress:"転出アドレス ",
    search:"検索 ",
    bgday:"開始日 ",
    endday:"終了日 ",
    checkday:"日付を選択してください ",
    kefu:"カスタマーサービスに連絡する ",
    invitecode:"レジストレーションコード",
    slang:"言語を切り替える",
  },
    //葡萄牙
  {
    joinnow:"Junte-se agora ",
    top1:"Comprometido em construir a maior plataforma defi do mundo integrando DEX, IMO e Dao ",
    top2:"Projecto mínimo 100 usdt ",
    top3:"Não há necessidade de transferir moeda / renda estável / saída livre ",
    wkjj:"Introdução à exploração mineira ",
    jjcontent:"Este projeto é sobre o protocolo blockchain. É como um banco central descentralizado, que estabelece o mercado monetário através de contratos inteligentes. Com base na oferta e demanda de ativos, o mercado monetário deduz a taxa de juros do pool de contribuições por algoritmo. O provedor de ativos usdt (participantes, nós os chamamos de mineiros) interage diretamente com o contrato inteligente, sem qualquer hipoteca real, ganha automaticamente taxas de juros flutuantes, e não precisa negociar termos como prazo, taxa de juros ou garantia com pares ou contrapartes. Permite que os titulares de usdt, após concordarem em autorizar a execução do contrato (tornando-se mineiros), explorem automaticamente as necessidades de empréstimo do mercado monetário e correspondam automaticamente as ordens com taxas de juros mais elevadas. É um mecanismo AMM e programa automático de quantificação. O depósito está sempre na sua conta, o que equivale ao seu depósito atual no banco. Os fundos dos depósitos à procura não foram retirados, mas os bancos usam-nos efectivamente para pedir empréstimos e ganhar juros. O acordo é o mesmo. Seu depósito está sempre em sua própria carteira. Você pode retirar os juros ganhos para sua carteira a qualquer momento sem retirada de dinheiro. Torne-se um mineiro, dê-o ao contrato inteligente e ganhe renda automaticamente! \n ",
    youshi:"Os nossos pontos fortes ",
    aqfxin:"Seguro e seguro ",
    aqfxinc:"Não há necessidade de transferir dinheiro. Usdt armazena sua própria carteira, 0 risco ",
    zytdui:"Equipe profissional ",
    zytduic:"Operação segura baseada na base de moedas e equipe de segurança de moeda ",
    dmkru:"Entrada de limiar baixo ",
    dmkruc:"Deposite a partir de 100usdt e compartilhe a renda de mineração do nó ",
    cjwti:"problema comum ",
    sjjgou:"Auditor ",
    hzhban:"parceiro cooperativo ",
    smsjd:"∆ qual é o benefício do nó? ",
    smssyl:"∆ qual é a taxa de retorno do armazenamento? ",
    cnalilai:"∆ de onde vem o rendimento da mineração? ",
    smshhjisuan:"∆ quando serão calculadas as receitas? ",
    srguize:"∆ regras de cálculo das receitas? ",
    smsjdc:"A Usdt armazena sua carteira com risco '0'. O gerenciamento de dados e o modo de operação da nova cadeia de blocos contribui para a ecologia descentralizada da defi. Depois que cada usuário provar seus ativos de criptografia, todos eles bloquearão automaticamente o nó para executar a receita através do contrato inteligente. Agora você pode participar de qualquer carteira e adotar uma nova geração de compartilhamento de nó. A receita total obtida através do compartilhamento de nós de liquidez será dividida na carteira centralizada de cada usuário através de tokens usdt ",
    smssylc:"O rendimento diário é de cerca de 0,5% - 4,8%; Quanto maior a cota de armazenamento usdt, maior o rendimento ",
    cnalilaic:"Todas as recompensas de armazenamento dadas aos usuários são automaticamente combinadas pelo contrato inteligente do nó usdt. Os provedores de ativos interagem diretamente com acordos para ganhar taxas de juros flutuantes sem negociar termos, taxas de juros ou garantias com pares ou contrapartes. Quanto mais usdts você segurar, mais recompensas você recebe. ",
    smshhjisuanc:"Após fazer login e autorizar a participação no nó de compartilhamento, a receita pode ser calculada no mesmo dia. Calculamos seu valor de token várias vezes ao dia (de vez em quando). Isso é equivalente ao seu depósito atual no banco. Se o sistema verificar o número de nós usdt a qualquer momento no mesmo dia e não houver saldo, você não terá renda. ",
    liudongjd:"Nó de liquidez total ",
    nowsl:"Valor atual da força calculada ",
    wkzsy:"Total das receitas mineiras ",
    nowstate:"estado actual ",
    ykswk:"Início da exploração mineira ",
    bizhi:"Valor monetário ",
    zrsy:"Ganhos de ontem ",
    wksy:"Rendimentos mineiros ",
    tiqsy:"Rendimentos de retirada ",
    index:"Índice ",
    tongji:"Pool ",
    me:"Minha ",
    time:"tempo ",
    address:"endereço ",
    hqpz:"Obter o voucher ",
    ktqje:"Montante recuperável ",
    tiqumingx:"Extrair detalhes ",
    sljs:"Introdução ao poder computacional ",
    dayin:"Rendimentos diários ",
    monthin:"Rendimentos mensais ",
    wallusdt:"Saldo da carteira ",
    emptydata:"sem dados ",
    waiting:"Em processo ",
    finished:"Concluído ",
    failed:"Falhou ",
    register:"registo ",
    inputicode:"Indique por favor o código do convite ",
    usdtout:"Transferir fundos ",
    bizhong:"moeda ",
    select:"Seleccione por favor ",
    zhuanzhangtype:"Rede de transferência ",
    outaddress:"Endereço de transferência ",
    search:"pesquisa ",
    bgday:"Data de início ",
    endday:"Data final ",
    checkday:"Seleccione por favor uma data ",
    kefu:"Contactar o serviço de apoio ao cliente ",
    invitecode:"Código de registo ",
    slang:"Selecção da língua ",
    invitation:"Convidar amigos ",
    login:"Entrar ",
    logout:"sair ",
    logac:"número da conta ",
    from:"de ",
    to:"ir para ",
    safepsw:"Senha de segurança ",
    inputsafepsw:"Introduza a senha de segurança ",
    number:"Quantidade ",
    amount:"montante de dinheiro ",
    pleaseamount:"Indique o montante ",
    price:"Preço ",
    active_number:"Saldo disponível ",
    complete_number:"Montante da transacção ",
    real_get:"Recebimento efectivo ",
    fee:"Taxa de serviço ",
    submit:"Enviar ",
    all:"inteiro ",
    onlyusdt:"Este endereço só pode receber usdt ",
    tips:"lembrete ",
    zhangdangliushui:"Dados relativos ao activo ",
    zhuangchujilu:"Transferir o registo ",
    withdrawlog:"Registo de retirada ",
    tuijian:"Configuração recomendada ",
    usdtaddress:"Endereço Usdt ",
    allzc:"Valor total do activo ",
    canusezc:"Activos disponíveis ",
    marketzc:"Activos de valor de mercado ",
    withdraw:"Retirada ",
    type:"tipo ",
    billtype:"Tipo de conta ",
    option:"operação ",
    beizu:"observações ",
    createtime:"Tempo de criação ",
    check:"consulta ",
    change:"modificar ",
    tuijianlink:"As minhas ligações recomendadas ",
    leijishouyi:"Rendimentos acumulados ",
    wodetuijian:"A minha recomendação ",
    leijituijian:"Recomendação cumulativa ",
    addusdtaddress:"Adicionar o endereço de retirada ",
    add:"adicionar a ",
    ccel:"cancelar ",
    copy:"cópia ",
    man:"Tempo de pessoa ",
    status:"estado ",
    plzhuanchunum:"Indique a quantidade de transferência ",
    pljieshouaddr:"Indique por favor o endereço de recepção ",
    pltxnum:"Indique a quantidade retirada ",
    clickcopy:"Clique em Copiar ",
    sendmessage:"enviar ",
    inputsearchword:"Introduza a pesquisa por palavras-chave ",
    copylink:"Copiar a ligação recomendada ",
    copycode:"Copiar o código da recomendação ",
    notice:"Foco Notícias ",
    save:"preservação ",
    article:"Informações mais recentes ",
    noticemsg:"Mensagem de notificação ",
    other:"outros ",
    tjjl:"Recompensa por recomendação ",
    sysadd:"Adicionar o sistema ",
    syssub:"Dedução do sistema ",
    success:"Operação bem sucedida ",
    regok:"o login foi bem sucedido",
  },
    //
  {
    joinnow:"Únete ahora ",
    top1:"Dedicado a construir la mayor Plataforma Mundial de defi que integra DEX, Imo y Dao ",
    top2:"Project minimum from 100 usdt ",
    top3:"No hay necesidad de conversión de divisas / ingresos sólidos / salida libre ",
    wkjj:"Introducción a la minería ",
    jjcontent:'Este proyecto se refiere al Protocolo de la cadena de bloques. Es como un banco central descentralizado que construye el mercado monetario a través de contratos inteligentes. El mercado monetario se basa en la oferta y la demanda de activos y deduce el tipo de interés de la reserva de activos mediante el algoritmo. Los proveedores de activos de la usdt (participantes, a los que llamamos "mineros") Interact úan directamente con los acuerdos de contratos inteligentes sin ninguna garantía real, ganan automáticamente tipos de interés flotantes y no negocian términos como términos de vencimiento y tipos de interés o garantías reales con pares o contrapartes. Permite a los titulares de la usdt explorar automáticamente la demanda de préstamos en el mercado monetario y emparejar automáticamente pedidos con intereses más altos después de acordar autorizar la ejecución del contrato (convirtiéndose en "mineros"). Es un mecanismo amm y un program a de cuantificación automática. El depósito siempre está en su cuenta, lo que equivale a su depósito actual en el Banco. Los depósitos a la vista no se retiran, pero los bancos los utilizan para pedir prestado y obtener intereses. El Protocolo es el mismo. Su depósito siempre está en su propia cartera, y usted puede retirar los intereses devengados a su cartera en cualquier momento sin retirar dinero. ¡Conviértete en un "minero", entrega el Acuerdo de contrato inteligente, gana ingresos automáticamente! ',
    youshi:"Nuestra ventaja ",
    aqfxin:"Seguridad ",
    aqfxinc:"No hay necesidad de transferir dinero, usdt mantiene su cartera, 0 riesgo ",
    zytdui:"Equipo profesional ",
    zytduic:"Funcionamiento seguro del equipo basado en la coinbase y la seguridad monetaria ",
    dmkru:"Umbral bajo ",
    dmkruc:"100 usdt para el almacenamiento y el uso compartido de los ingresos de la minería nodal ",
    cjwti:"Preguntas frecuentes ",
    sjjgou:"Organismo de auditoría ",
    hzhban:"Partner ",
    smsjd:"¿Qué son los ingresos de los nodos? ",
    smssyl:"¿Cuál es el rendimiento de las existencias? ",
    cnalilai:"¿De dónde vienen los ingresos de la minería? ",
    smshhjisuan:"¿Cuándo comienza el cálculo de los ingresos? ",
    srguize:"¿• Normas de cálculo de los ingresos? ",
    smsjdc:"Usdt almacena su cartera con 0 de riesgo, y la gestión de datos y el modo de operación de la nueva cadena de bloques contribuyen a la ecología descentralizada de defi. Después de que cada usuario demuestre que tiene un activo encriptado, todo esto bloquea automáticamente el nodo para ejecutar los ingresos a través de un contrato inteligente. Ahora usted puede participar en cualquier cartera y compartir nodos de nueva generación. Los ingresos totales obtenidos mediante el uso compartido de nodos de liquidez se dividirán en carteras centralizadas para cada usuario a través de fichas usdt ",
    smssylc:"El rendimiento diario es de aproximadamente 0,5% - 4,8%; Cuanto mayor sea la cuota de almacenamiento de usdt, mayor será el rendimiento ",
    cnalilaic:"Todas las recompensas de almacenamiento concedidas a los usuarios son automáticamente emparejadas por el contrato inteligente del nodo usdt. Los proveedores de activos Interact úan directamente con los acuerdos para obtener tipos de interés variables, sin tener que negociar condiciones, tipos de interés o garantías reales con sus pares o contrapartes. Cuanto más usdt tengas, más recompensas tendrás. ",
    smshhjisuanc:"Después de iniciar sesión y autorizar la participación en el nodo compartido, el cálculo de los ingresos puede comenzar ese día. Calculamos el valor de su Token varias veces al día (de vez en cuando). Esto equivale a su depósito actual en el Banco. Si el sistema comprueba el número de nodos usdt en cualquier momento del mismo día y no hay saldo, no obtendrá ningún beneficio. ",
    liudongjd:"Nodo de liquidez total ",
    nowsl:"Fuerza calculada actual ",
    wkzsy:"Ingresos totales de la minería ",
    nowstate:"Estado actual ",
    ykswk:"Ha comenzado a cavar ",
    bizhi:"Valor monetario ",
    zrsy:"Ganancias de ayer ",
    wksy:"Ingresos procedentes de la minería ",
    tiqsy:"Ingresos procedentes de la extracción ",
    index:"Índice ",
    tongji:"Pool ",
    me:"Mine ",
    time:"Tiempo ",
    address:"Dirección ",
    hqpz:"Obtener credenciales ",
    ktqje:"Importe recuperable ",
    tiqumingx:"Extraer detalles ",
    sljs:"Introducción al cálculo de la Fuerza ",
    dayin:"Ingresos diarios ",
    monthin:"Ingresos mensuales ",
    wallusdt:"Saldo de la cartera ",
    emptydata:"No hay datos ",
    waiting:"En proceso ",
    finished:"Completado ",
    failed:"Falló ",
    register:"Registro ",
    inputicode:"Introduzca el Código de invitación ",
    usdtout:"Transferencia de fondos ",
    bizhong:"Moneda ",
    select:"Por favor, elija ",
    zhuanzhangtype:"Red de transferencia ",
    outaddress:"Dirección de transferencia ",
    search:"Buscar ",
    bgday:"Fecha de inicio ",
    endday:"Fecha final ",
    checkday:"Por favor, seleccione una fecha ",
    kefu:"Contacto con el servicio al cliente ",
    invitecode:"Código de registro ",
    slang:"Elección del idioma ",
    invitation:"Invitar a amigos ",
    login:"Iniciar sesión ",
    logout:"Salida ",
    logac:"Número de cuenta ",
    from:"De ",
    to:"Ir a ",
    safepsw:"Contraseña de Seguridad ",
    inputsafepsw:"Introduzca la contraseña de Seguridad ",
    number:"Cantidad ",
    amount:"Importe ",
    pleaseamount:"Introduzca la cantidad ",
    price:"Precio ",
    active_number:"Saldo disponible ",
    complete_number:"Volumen de Negocios ",
    real_get:"Llegada efectiva ",
    fee:"Gastos de tramitación ",
    submit:"Submission ",
    all:"Todos ",
    onlyusdt:"Esta dirección sólo puede recibir usdt ",
    tips:"Consejos cálidos ",
    zhangdangliushui:"Detalles de los activos ",
    zhuangchujilu:"Registro de transferencia ",
    withdrawlog:"Registro de retirada ",
    tuijian:"Configuración recomendada ",
    usdtaddress:"Dirección usdt ",
    allzc:"Valor total de los activos ",
    canusezc:"Activos disponibles ",
    marketzc:"Activos de mercado ",
    withdraw:"Presentación ",
    type:"Tipo ",
    billtype:"Tipo de factura ",
    option:"Operaciones ",
    beizu:"Observaciones ",
    createtime:"Tiempo de creación ",
    check:"Consulta ",
    change:"Modificar ",
    tuijianlink:"Mi enlace de recomendación ",
    leijishouyi:"Ingresos acumulados ",
    wodetuijian:"Mi recomendación ",
    leijituijian:"Recomendación acumulativa ",
    addusdtaddress:"Añadir dirección de retiro ",
    add:"Añadir ",
    ccel:"Cancelar ",
    copy:"Copiar ",
    man:"Número de personas ",
    status:"Estado ",
    plzhuanchunum:"Introduzca el número de salidas ",
    pljieshouaddr:"Introduzca la dirección de recepción ",
    pltxnum:"Por favor, introduzca la cantidad retirada ",
    clickcopy:"Haga clic en copiar ",
    sendmessage:"Enviar ",
    inputsearchword:"Introduzca la búsqueda de palabras clave ",
    copylink:"Copiar enlaces recomendados ",
    copycode:"Copiar Código de recomendación ",
    notice:"Noticias importantes ",
    save:"Guardar ",
    article:"Información actualizada ",
    noticemsg:"Mensaje de notificación ",
    other:"Otros ",
    tjjl:"Recompensa recomendada ",
    sysadd:"Añadir sistema ",
    syssub:"Deducción del sistema ",
    success:"Operación exitosa ",
    regok:"Registro exitoso",
  },
    //
  {
    joinnow:"Rejoignez - nous maintenant. ",
    top1:"Dédié à la plus grande plate - forme defi au monde intégrant DEX, IMO et Dao ",
    top2:"À partir de 100 USD minimum pour le projet ",
    top3:"Pas besoin de conversion / revenu stable / sortie libre ",
    wkjj:"Introduction à l'exploitation minière ",
    jjcontent:"Ce projet concerne le Protocole blockchain. C'est comme une banque centrale décentralisée qui construit un marché monétaire par des contrats intelligents. Sur la base de l'offre et de la demande d'actifs, le taux d'intérêt du pool d'investissement est calculé par algorithme. Les fournisseurs d'actifs usdt (participants, que nous appelons mineurs) interagissent directement avec les accords de contrats intelligents sans aucune garantie réelle, gagnent automatiquement des taux d'intérêt flottants et n'ont pas besoin de négocier des conditions telles que des termes et des taux d'intérêt ou des garanties avec leurs pairs ou contreparties. Il permet aux détenteurs d'USD d'explorer automatiquement les besoins d'emprunt du marché monétaire et de faire correspondre automatiquement les commandes à des taux d'intérêt plus élevés après avoir accepté d'autoriser l'exécution du contrat (en tant que « mineur»). Il s'agit d'un mécanisme AMM et d'un programme de quantification automatique. Le dépôt est toujours sur votre compte, ce qui équivaut à votre dépôt actuel à la Banque. L'argent des dépôts à vue n'est pas retiré, mais les banques l'utilisent pour emprunter et gagner des intérêts. Le Protocole est le même. Votre dépôt est toujours dans votre propre portefeuille et vous pouvez retirer les intérêts gagnés dans votre portefeuille à tout moment. Devenez un mineur, donnez à l'Accord de contrat intelligent, gagnez automatiquement des revenus! ",
    youshi:"Nos forces ",
    aqfxin:"Soyez en sécurité. ",
    aqfxinc:"Pas besoin de changer de monnaie, usdt stocke son portefeuille, '0' risque ",
    zytdui:"Équipe professionnelle ",
    zytduic:"Opérations de sécurité basées sur coinbase et l'équipe de sécurité des pièces ",
    dmkru:"Seuil bas ",
    dmkruc:"Stockage à partir de 100 USD et partage des revenus miniers des noeuds ",
    cjwti:"Questions fréquemment posées ",
    sjjgou:"Organes d & apos; audit ",
    hzhban:"Partenaires ",
    smsjd:"∆ qu’est - ce qu’un revenu de noeud? ",
    smssyl:"∆ quel est le rendement du stockage? ",
    cnalilai:"∆ d’où vient le produit de l’exploitation minière? ",
    smshhjisuan:"∆ quand commence le calcul du revenu? ",
    srguize:"∆ règles de calcul du revenu? ",
    smsjdc:"Usdt stocke son portefeuille à risque « 0», et le mode de gestion et d'exploitation des données de la nouvelle chaîne de blocs contribue à l'écologie décentralisée du defi. Une fois que chaque utilisateur a prouvé qu'il possède des actifs cryptographiques, tout cela verrouille automatiquement les noeuds pour effectuer des gains par des contrats intelligents. Vous pouvez maintenant participer à n'importe quel portefeuille, en utilisant la nouvelle génération de partage de noeuds. Les gains totaux réalisés grâce au partage des noeuds de liquidité seront partagés entre les portefeuilles centralisés de chaque utilisateur au moyen de jetons usdt. ",
    smssylc:"Le rendement quotidien est d'environ 0,5% - 4,8%; Plus la limite de stockage usdt est élevée, plus le rendement est élevé. ",
    cnalilaic:"Toutes les récompenses de stockage accordées aux utilisateurs sont automatiquement appariées par le contrat intelligent du noeud usdt. Les fournisseurs d'actifs interagissent directement avec l'accord pour obtenir un taux d'intérêt variable sans négocier de conditions, de taux d'intérêt ou de garanties avec leurs pairs ou contreparties. Plus vous détenez de tdu, plus vous recevez de récompenses. ",
    smshhjisuanc:"Une fois que vous êtes connecté et autorisé à participer au noeud de partage, vous pouvez commencer à calculer les revenus le même jour. Nous calculons la valeur de vos jetons plusieurs fois par jour (de temps à autre). Cela équivaut à votre dépôt actuel à la Banque. Si le système vérifie le nombre de noeuds usdt à tout moment le même jour et qu'il n'y a pas de solde, vous n'aurez aucun avantage. ",
    liudongjd:"Total des nœuds de liquidité ",
    nowsl:"Valeur actuelle de la Force calculée ",
    wkzsy:"Total des recettes minières ",
    nowstate:"État actuel ",
    ykswk:"Début de l'exploitation minière ",
    bizhi:"Valeur monétaire ",
    zrsy:"Gains d'hier ",
    wksy:"Revenus de l'exploitation minière ",
    tiqsy:"Retrait du produit ",
    index:"Index ",
    tongji:"Pool ",
    me:"Mine ",
    time:"Temps ",
    address:"Adresse ",
    hqpz:"Obtenir des justificatifs d'identité ",
    ktqje:"Montant disponible ",
    tiqumingx:"Extraire les détails ",
    sljs:"Introduction au calcul ",
    dayin:"Gains journaliers ",
    monthin:"Gains mensuels ",
    wallusdt:"Solde du portefeuille ",
    emptydata:"Aucune donnée disponible ",
    waiting:"En cours de traitement ",
    finished:"Terminé ",
    failed:"Échec ",
    register:"Inscription ",
    inputicode:"Veuillez saisir le Code d'invitation ",
    usdtout:"Transfert de fonds ",
    bizhong:"Monnaie ",
    select:"Veuillez sélectionner ",
    zhuanzhangtype:"Réseau de transfert ",
    outaddress:"Adresse de transfert ",
    search:"Recherche ",
    bgday:"Date de début ",
    endday:"Date de fin ",
    checkday:"Veuillez sélectionner une date ",
    kefu:"Contactez le service à la clientèle ",
    invitecode:"Code d'inscription ",
    slang:"Choix de la langue ",
    invitation:"Inviter des amis ",
    login:"Connexion ",
    logout:"Sortie ",
    logac:"Numéro de compte ",
    from:"De ",
    to:"Aller à ",
    safepsw:"Mot de passe sécurisé ",
    inputsafepsw:"Veuillez saisir le mot de passe de sécurité ",
    number:"Nombre ",
    amount:"Montant ",
    pleaseamount:"Veuillez entrer le montant ",
    price:"Prix ",
    active_number:"Solde disponible ",
    complete_number:"Montant de la transaction ",
    real_get:"Recettes effectives ",
    fee:"Frais de manutention ",
    submit:"Présentation ",
    all:"Tous ",
    onlyusdt:"Cette adresse ne peut recevoir que usdt ",
    tips:"Conseils chaleureux ",
    zhangdangliushui:"Ventilation des actifs ",
    zhuangchujilu:"Enregistrement du transfert ",
    withdrawlog:"Comptes de retrait ",
    tuijian:"Paramètres recommandés ",
    usdtaddress:"Adresse usdt ",
    allzc:"Valeur totale des actifs ",
    canusezc:"Actifs disponibles ",
    marketzc:"Actifs à valeur de marché ",
    withdraw:"Retrait ",
    type:"Type ",
    billtype:"Type de facture ",
    option:"Fonctionnement ",
    beizu:"Remarques ",
    createtime:"Temps de création ",
    check:"Recherche ",
    change:"Modifier ",
    tuijianlink:"Mes liens recommandés ",
    leijishouyi:"Recettes cumulées ",
    wodetuijian:"Ma recommandation ",
    leijituijian:"Recommandation cumulative ",
    addusdtaddress:"Ajouter une adresse de retrait ",
    add:"Ajouter ",
    ccel:"Annulation ",
    copy:"Copier ",
    man:"Nombre de personnes ",
    status:"Statut ",
    plzhuanchunum:"Veuillez entrer la quantité de transfert ",
    pljieshouaddr:"Veuillez saisir l'adresse de réception ",
    pltxnum:"Veuillez entrer la quantité de retrait ",
    clickcopy:"Cliquez pour copier ",
    sendmessage:"Envoyer ",
    inputsearchword:"Veuillez saisir un mot - clé pour la recherche ",
    copylink:"Copier le lien recommandé ",
    copycode:"Copier les références ",
    notice:"Faits saillants ",
    save:"Enregistrer ",
    article:"Mise à jour ",
    noticemsg:"Message de notification ",
    other:"Autres ",
    tjjl:"Récompenses recommandées ",
    sysadd:"Ajout du système ",
    syssub:"Déduction du système ",
    success:"Opération réussie ",
    regok:"Inscription réussie",
  }
]

export const langs =["English","简体中文","繁體中文","한국어.","日本語","Português","Español","Français"]

export function lindex() {
  //默认语言
  var lang = localStorage.getItem("lang");
  if(lang){
    return lang
  }else{
    localStorage.setItem("lang","0");
    return 0;
  }
}
