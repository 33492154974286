import ElementUI from 'element-ui' //新添加
import locale from 'element-ui/lib/locale/lang/en'
//import 'element-ui/lib/theme-chalk/index.css' //新添加，避免后期打包样式不同，要放在import App from './App';之前
//引入自定义主题
import './theme/index.css'
import WotDesign from 'wot-design'
import 'wot-design/lib/theme-default/index.css'
import { Icon } from 'wot-design'
import 'flag-icons/css/flag-icons.css'


import Vue from 'vue'
import App from './App'
import router from './router'
import axios from 'axios'
import { VueJsonp } from 'vue-jsonp'//解决跨域

Vue.use(VueJsonp)
// Vue.use(ElementUI)   //新添加
Vue.use(WotDesign)   //新添加
Vue.use(Icon)
Vue.use(ElementUI, { locale })

Vue.config.productionTip = false
Vue.prototype.$http= axios

//多语言引入
import { ALLLANG } from '@/api/lang'
import { lindex } from '@/api/lang'
Vue.prototype.$LANG = ALLLANG[lindex()];//html和代码中直接用 this.$LANG 即可
// Vue.prototype.$IMG_URL = "https://images.fxcoinbtc.com/";
// Vue.prototype.$IMG_UPLOAD_URL = "https://images.fxcoinbtc.com/up.php";
// Vue.prototype.$WEB_URL = "https://web.fxcoinbtc.com/";

Vue.prototype.$IMG_URL = "https://api.defi8.vip/";
Vue.prototype.$IMG_UPLOAD_URL = "https://api.defi8.vip/api/v1/UploadImg";
Vue.prototype.$WEB_URL = "https://www.defi8.vip/";
// Vue.prototype.$WEB_URL = "http://118.107.45.54:8096/";
import VueSocketIO from 'vue-socket.io'

/**
 * 这里记得配置到 nginx 的配置文件里，实现转发
 * (api.xxxx.com的配置文件里添加)
 *
 location ~/(wss|socket.io) {
        # 此处改为 socket.io 后端的 ip 和端口即可
        proxy_pass http://127.0.0.1:19968;
        proxy_set_header Upgrade $http_upgrade;
        proxy_set_header Connection "upgrade";
        proxy_http_version 1.1;
        proxy_set_header X-Forwarded-For $proxy_add_x_forwarded_for;
        proxy_set_header Host $host;
    }
 */
Vue.use(new VueSocketIO({
  debug: false,
  // connection:'https://api.fxcoinbtc.com',
  connection:'https://api.defi8.vip',
  // connection:'http://127.0.0.1:19968',
}))

/* eslint-disable no-new */
// template模式
// new Vue({
//   el: '#app',
//   router,
//   components: { App },
//   template: '<App/>',
// })

// running-time 模式
new Vue({
  router,
  // store,
  render: h => h(App)
}).$mount("#app")

