<template>

  <div>

    <wd-navbar :title="this.$LANG.tiqumingx" >
      <span slot="left" @click="goback">
        <wd-icon name="arrow-left" />
      </span>
      <span slot="right" @click="search = true">
      <wd-icon class="right" name="filter" />
    </span>
    </wd-navbar>

    <wd-popup v-model="search" position="top" style="padding: 20px">
      <el-form label-position="left" label-width="80px" :model="listForm" status-icon  ref="listForm" >
        <el-form-item :label="$LANG.bgday">
          <wd-datetime-picker type="date" v-model="listForm.begin" confirm-button-text="OK" cancel-button-text="Cancel" :placeholder="$LANG.checkday"></wd-datetime-picker>
        </el-form-item>
        <el-form-item :label="$LANG.endday">
          <wd-datetime-picker type="date" v-model="listForm.end" confirm-button-text="OK" cancel-button-text="Cancel" :placeholder="$LANG.checkday"></wd-datetime-picker>
        </el-form-item>

        <wd-button type="primary" @click="submitForm('listForm')" style="width: 100%">{{$LANG.search}}</wd-button>

      </el-form>

    </wd-popup>

    <el-container>

      <el-main>

        <div class="list-container">
          <div class="list">
            <div v-for="(item, index) in tableData" :key="index" class="list-item">

              <div style="height: 60px;border-bottom: 1px solid #f5f5f5;padding: 10px 0px" class="content">
                <img :src="require('@/assets/usdt.png')" width="30" height="30" alt="joy" style="border-radius: 4px; margin-right: 12px;" />
                <div style="width: 100%">
                  <div style="color: rgba(0,0,0,0.85); font-size: 16px;text-align: left;padding: 5px 0px">{{ item.number }}
                    <span style="float: right;">
                      <wd-tag type="primary" v-if="item.status==0">{{$LANG.waiting}}</wd-tag>
                      <wd-tag type="success" v-if="item.status==1">{{$LANG.finished}}</wd-tag>
                      <wd-tag type="danger" v-if="item.status==2">{{$LANG.failed}}</wd-tag>
                    </span>
                  </div>
                  <div style="color: rgba(0,0,0,0.55); font-size: 12px;text-align: left;padding: 5px 0px" >{{item.created_at}}</div>
                  <div style="font-size: 12px;text-align: left;padding: 5px 0px">{{item.in_address}}</div>
                </div>

              </div>

            </div>
          </div>
          <div style="width: 100%;" v-if="loading">
            <wd-loading style="margin: auto"></wd-loading>
          </div>
          <el-empty :description="$LANG.emptydata" style="width: 50%;margin: auto" v-if="count < 1"></el-empty>
        </div>
      </el-main>
    </el-container>

  </div>
</template>
<style>
  .content, .title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .content {
    justify-content: flex-start;
  }
  .title {
    justify-content: space-between;
  }
  .title-tip {
    color: rgba(0, 0, 0, 0.25);
    font-size: 12px;
  }
</style>

<script>

  import { TokenApi } from '@/api/interface' // 引入接口
  import { dateFormat } from '@/api/units' // 引入接口
  export default {
    data() {
      return {
        search: false,
        drawer: false,
        loading: true,
        nomore:false,
        count:1,
        page:0,
        ishide:false,
        listForm: {
          begin: "",
          end: new Date(),
          page: 1,
        },
        tableData: [],
      }
    },
    components: {},
    methods:{
      goback(){
        this.$router.go(-1)
      },
      onScroll(){
        //可滚动容器的高度
        let innerHeight = this.$el.clientHeight;
        //屏幕尺寸高度
        let outerHeight = document.documentElement.clientHeight;
        //可滚动容器超出当前窗口显示范围的高度
        let scrollTop = document.documentElement.scrollTop;
        //避免切换时读取到异常高度
        if(scrollTop==0){
          innerHeight=10000
        }
        //scrollTop在页面为滚动时为0，开始滚动后，慢慢增加，滚动到页面底部时，出现innerHeight < (outerHeight + scrollTop)的情况，严格来讲，是接近底部。
        // console.log(innerHeight + " " + outerHeight + " " + scrollTop);
        if(innerHeight <= outerHeight + scrollTop){
          //此处添加自定义操作
          if(!this.loading && !this.nomore){
            this.loadmore()
          }
        }
      },
      loadmore () {
        this.loading = true
        let postData = this.listForm
        if(postData.begin){
          postData.begin = dateFormat("YY-mm-dd",postData.begin)
          console.log(postData.begin)
        }
        if(postData.end){
          postData.end = dateFormat("YY-mm-dd",postData.end)
          console.log(postData.end)
        }
        TokenApi('/UsdtOutLog',postData,this,'get').then(data => {
          if(data.status==0){
            this.count = data.data.count
            if(data.data.data.length){
              this.tableData = this.tableData.concat(data.data.data);
              this.listForm.page++
            }else{
              this.nomore = true
            }
          }else{
            this.nomore = true
            this.$message.error(data.msg);
          }
          this.loading = false
        })

      },

      getData_new(postData){
        if(postData.begin){
          postData.begin = dateFormat("YY-mm-dd",postData.begin)
          console.log(postData.begin)
        }
        if(postData.end){
          postData.end = dateFormat("YY-mm-dd",postData.end)
          console.log(postData.end)
        }
        TokenApi('/outLog',postData,this,'get').then(data => {
          this.loading = false
          if(data.status==0){
            this.count = data.data.count
            if(data.data.data.length){
              this.tableData = data.data.data;
              this.listForm.page++
            }else{
              this.$refs.loadmore.loadEnd()
              // this.$refs.loadmore.reset()
            }
          }else{
            this.$refs.loadmore.loadEnd()
            this.$message.error(data.msg);
          }
        })
        this.search = false;

      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.listForm.page = 1
            this.getData_new(this.listForm);
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },

    },
    created() {
      //默认菜单
      //this.getData(this.listForm);
      this.loadmore()
    },
    mounted(){
      //开启滚动事件
      window.addEventListener("scroll", this.onScroll);
    },
    destroyed(){
      //关闭滚动事件
      window.removeEventListener("scroll",( this.onScroll));
    }
  };
</script>
