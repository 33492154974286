<template>

  <el-menu
    :default-active="activeIndex"
    class="el-menu-demo"
    mode="horizontal"
    @select="handleSelect"
    background-color="#0B0E11"
    text-color="#fff"
    active-text-color="#ffd04b"
    style="padding-left: 20px;border-color:#12161C;font-weight: bold;height: 80px;line-height: 80px"
  >

    <el-menu-item index="0" style="width: 150px;height: 80px"><el-image :src="require('@/assets/logo2.jpg')" style="width: 100%;height: 40px"></el-image></el-menu-item>
    <el-menu-item index="1">{{this.$LANG.index}}</el-menu-item>
    <el-menu-item index="2">{{this.$LANG.trade}}</el-menu-item>
    <el-menu-item index="5">{{this.$LANG.ctrade}}</el-menu-item>
    <!--    <el-menu-item index="3">Exchange</el-menu-item>-->
    <el-submenu index="3">
      <template slot="title"><span style="font-size: 16px;">{{this.$LANG.exchange}}</span></template>
      <el-menu-item index="3-1">{{this.$LANG.usdtin}}</el-menu-item>
      <el-menu-item index="3-2">{{this.$LANG.usdtout}}</el-menu-item>
      <el-menu-item index="3-3">{{this.$LANG.transfer}}</el-menu-item>

    </el-submenu>
    <el-menu-item index="4">{{this.$LANG.me}}</el-menu-item>


    <el-menu-item index="6" style="float: right">{{this.$LANG.kefu}}</el-menu-item>

    <el-menu-item style="float: right">
      <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link">
              <div  v-if="activelangeindex==0">
                <el-image :src="require('@/assets/en.png')" ></el-image> {{activelang}}<i class="el-icon-arrow-down el-icon--right"></i>
              </div>
              <div  v-if="activelangeindex==1">
                <el-image :src="require('@/assets/cn.png')" ></el-image> {{activelang}}<i class="el-icon-arrow-down el-icon--right"></i>
              </div>
              <div  v-if="activelangeindex==2">
                <el-image :src="require('@/assets/cn.png')" ></el-image> {{activelang}}<i class="el-icon-arrow-down el-icon--right"></i>
              </div>
              <div  v-if="activelangeindex==3">
                <el-image :src="require('@/assets/ko.png')" ></el-image> {{activelang}}<i class="el-icon-arrow-down el-icon--right"></i>
              </div>
              <div  v-if="activelangeindex==4">
                <el-image :src="require('@/assets/jp.png')" ></el-image> {{activelang}}<i class="el-icon-arrow-down el-icon--right"></i>
              </div>

            </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="0">English</el-dropdown-item>
          <el-dropdown-item command="1">简体中文</el-dropdown-item>
          <el-dropdown-item command="2">繁體中文</el-dropdown-item>
          <el-dropdown-item command="3">한국어.</el-dropdown-item>
          <el-dropdown-item command="4">日本語</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </el-menu-item>



    <el-menu-item  style="float: right">
      <el-button size="mini" v-if="token" type="primary" plain @click="logout" style="color: #333">{{this.$LANG.logout}}</el-button>
      <router-link to="login" v-else><el-button size="mini" type="primary" plain style="color: #333">{{this.$LANG.login}}</el-button></router-link>
    </el-menu-item>
    <el-menu-item style="float: right"><el-button size="mini" type="info" plain @click="register" v-if="!token" style="color: #333">{{this.$LANG.register}}</el-button></el-menu-item>


  </el-menu>




</template>

<script>
  import { langs } from '@/api/lang'
  import { lindex } from '@/api/lang'
  export default {
    name: "Header",
    props: {
      token:{
        type: String,
        default:localStorage.getItem("accessToken")
      },
      // activeIndex:{
      //   type: String,
      //   default:"1"
      // }
    },
    data() {
      return {
        activeIndex: localStorage.getItem("activeIndex"),
        activelang:langs[lindex()],
        activelangeindex:lindex(),
        //token:localStorage.getItem("accessToken")
      };
    },
    methods: {
      handleCommand(val){
        localStorage.setItem("lang",val)
        this.$router.go(0)
      },
      handleSelect(key, keyPath) {
        console.log(key, keyPath);
        if(key == 1){
          this.$router.push({ path: '/index' });

        }else if(key == '3-2'){
          this.$router.push({ path: '/usdtout' });
        }else if(key == 4){
          this.$router.push({ path: '/me' });
        }
      },
      //登录用router-link 这里注册把跳转写到JS
      register(){
        this.$router.push({ path: '/register' });
      },
      logout(){
        localStorage.setItem('accessToken','');
        //退出后通知父组件，让父组件通知其他组件
        //父组件收到后又传回来通知变动
        this.$emit('childFn', "");

        this.$message({
          message: '您已退出登录',
          type: 'success'
        });
        this.$router.push({ path: '/index' });
      }
    },

  }
</script>

<style scoped>
  .el-dropdown-link {
    cursor: pointer;
    color: white;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
  .el-image{
    width: 22px; height: 22px;
  }
  .el-menu-item{
    font-size: 16px;
    height: 80px;
    line-height: 80px;
  }
</style>
